// const BASE_URL = "https://192.168.0.3:30000/";
const BASE_URL = "https://hybis.co.kr:30000/";

export const ApiUrl = {
    SIGN_IN: BASE_URL + "signIn",
    SIGN_OUT: BASE_URL + "signOut",
    CHANGE_PASSWORD: BASE_URL + "password",
    YEAR_RANGE: BASE_URL + "chart/yearRange",
    MONTHLY_PRODUCTION: (year: number) => `${BASE_URL}chart/monthlyProduction/${year}`,
    MONTHLY_WORK_TIME: (year: number) => `${BASE_URL}chart/monthlyWorkTime/${year}`,
    WELDING_HISTORY: (startDate: string, endDate: string, lineName: string, itemCode: string, itemName: string, accountName: string) => `${BASE_URL}welding/history?startDate=${startDate}&endDate=${endDate}&lineName=${lineName}&itemCode=${itemCode}&itemName=${itemName}&accountName=${accountName}`,
    DOWNLOAD_WELDING_HISTORY: (startDate: string, endDate: string, lineName: string, itemCode: string, itemName: string, accountName: string) => `${BASE_URL}welding/history/download?startDate=${startDate}&endDate=${endDate}&lineName=${lineName}&itemCode=${itemCode}&itemName=${itemName}&accountName=${accountName}`,
    WELDING_PERFORMANCE_LINE: BASE_URL + "welding/performance/line",
    WELDING_PERFORMANCE: (startDate: string, endDate: string, lineName: string) => `${BASE_URL}welding/performance?startDate=${startDate}&endDate=${endDate}&lineName=${lineName}`,
    DOWNLOAD_WELDING_PERFORMANCE: (startDate: string, endDate: string, lineName: string) => `${BASE_URL}welding/performance/download?startDate=${startDate}&endDate=${endDate}&lineName=${lineName}`,
    LINE_DEFECT_RATE: (year: number) => `${BASE_URL}chart/lineDefectRate/${year}`,
    ITEM_DEFECT_RATE: (year: number) => `${BASE_URL}chart/itemDefectRate/${year}`,
    DEPARTMENT: BASE_URL + "info/department",
    SEARCH_DEPARTMENT: (name: string) => `${BASE_URL}info/department/search?name=${name}`,
    DELETE_DEPARTMENT: BASE_URL + "info/department/delete",
    POSITION: BASE_URL + "info/position",
    SEARCH_POSITION: (name: string) => `${BASE_URL}info/position/search?name=${name}`,
    DELETE_POSITION: BASE_URL + "info/position/delete",
    LINE: BASE_URL + "info/line",
    SEARCH_LINE: (name: string) => `${BASE_URL}info/line/search?name=${name}`,
    DELETE_LINE: BASE_URL + "info/line/delete",
    ACCOUNT: BASE_URL + "info/account",
    SEARCH_ACCOUNT: (departmentName: string, positionName: string, name: string, country: string) => `${BASE_URL}info/account?departmentName=${departmentName}&positionName=${positionName}&name=${name}&country=${country}`,
    DOWNLOAD_ACCOUNT: (departmentName: string, positionName: string, name: string, country: string) => `${BASE_URL}info/account/download?departmentName=${departmentName}&positionName=${positionName}&name=${name}&country=${country}`,
    DELETE_ACCOUNT: BASE_URL + "info/account/delete",
    ITEM: BASE_URL + "info/item",
    SEARCH_ITEM: (type: string, code: string, name: string, lineName: string) => `${BASE_URL}info/item?type=${type}&code=${code}&name=${name}&lineName=${lineName}`,
    DOWNLOAD_ITEM: (type: string, code: string, name: string, lineName: string) => `${BASE_URL}info/item/download?type=${type}&code=${code}&name=${name}&lineName=${lineName}`,
    DELETE_ITEM: BASE_URL + "info/item/delete",
}