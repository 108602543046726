export interface WeldingHistorySearchDto {
    startDate: string,
    endDate: string,
    lineName: string,
    itemCode: string,
    itemName: string,
    accountName: string
}

export const emptyWeldingHistorySearchDto: WeldingHistorySearchDto = {
    startDate: "",
    endDate: "",
    lineName: "",
    itemCode: "",
    itemName: "",
    accountName: ""
}