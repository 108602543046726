import { ApiUrl } from "./ApiUrl";

export enum SubInfoType {
    DEPARTMENT, POSITION, LINE
}

export const getSubInfoName = (subInfoType: SubInfoType) => {
    switch (subInfoType) {
        case SubInfoType.DEPARTMENT:
            return "부서";
        case SubInfoType.POSITION:
            return "직급";
        case SubInfoType.LINE:
            return "LINE";
    }
}

export const getSubInfoUrl = (subInfoType: SubInfoType) => {
    switch (subInfoType) {
        case SubInfoType.DEPARTMENT:
            return ApiUrl.DEPARTMENT;
        case SubInfoType.POSITION:
            return ApiUrl.POSITION;
        case SubInfoType.LINE:
            return ApiUrl.LINE;
    }
}

export const getSubInfoDeleteUrl = (subInfoType: SubInfoType) => {
    switch (subInfoType) {
        case SubInfoType.DEPARTMENT:
            return ApiUrl.DELETE_DEPARTMENT;
        case SubInfoType.POSITION:
            return ApiUrl.DELETE_POSITION;
        case SubInfoType.LINE:
            return ApiUrl.DELETE_LINE;
    }
}