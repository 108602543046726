import { Button, Form, Modal } from "react-bootstrap";
import { SubInfoType, getSubInfoName, getSubInfoUrl } from "../../../../constants/SubInfoType";
import { FormEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import { SubInfoDto } from "../dto/SubInfoDto";
import { useSetRecoilState } from "recoil";
import { toastState } from "../../../../store/toastStore";

export interface CreateProps {
    subInfoType: SubInfoType;
    isUpdate: boolean;
    dto?: SubInfoDto;
    onCreated: () => void;
    onClose: () => void;
}

function CreateSubInfo(props: CreateProps) {
    const setToasts = useSetRecoilState(toastState);
    const inputRef = useRef<HTMLInputElement>(null);

    const [validated, setValidated] = useState(false);
    const [name, setName] = useState<string>("");
    const [isFailed, setIsFailed] = useState(false);

    useEffect(() => {
        if (props.dto?.name) {
            setName(props.dto.name);
        }
    }, [props.dto]);

    useEffect(() => {
        inputRef.current?.focus();
    }, [inputRef]);

    const getTitle = () => {
        return `${getSubInfoName(props.subInfoType)} ${props.isUpdate ? "이름 수정" : "추가"}`;
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setValidated(true);

        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity()) {
            if (props.isUpdate) {
                axios.put(getSubInfoUrl(props.subInfoType), {seq: props.dto?.seq, name: name}).then(() => {
                    setToasts((oldToasts) => [...oldToasts, {
                        id: Date.now(),
                        title: getTitle(),
                        message: "수정되었습니다."
                    }]);

                    props.onCreated();
                }).catch(() => {
                    setIsFailed(true);
                });
            } else {
                axios.post(getSubInfoUrl(props.subInfoType), {name: name}).then(() => {
                    setToasts((oldToasts) => [...oldToasts, {
                        id: Date.now(),
                        title: getTitle(),
                        message: "추가되었습니다."
                    }]);
    
                    props.onCreated();
                }).catch(() => {
                    setIsFailed(true);
                });
            }
        }
    }

    return (
        <Modal show={true} onHide={() => props.onClose()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {getTitle()}
                </Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Control
                        ref={inputRef}
                        type="text"
                        placeholder="이름을 입력해 주세요."
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    {isFailed &&
                        <div className="mt-2 text-danger ts-6">
                            ※ 이미 등록된 이름입니다.
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="submit"
                        className="main-button"
                    >
                        {`${props.isUpdate ? "수정" : "추가"}하기`}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default CreateSubInfo;