import { SearchResultDto } from "./dto/SearchResultDto";

export interface SearchResultProps {
    top?: number | undefined;
    left?: number | undefined;
    width: number | undefined;
    searchResult: SearchResultDto[];
    onSelect: (result: SearchResultDto) => void;
}

function SearchResult(props: SearchResultProps) {
    return (
        <div
            className="search-result-wrapper"
            style={{ top: props.top, left: props.left, width: props.width }}
        >
            {props.searchResult.map((result, index) => (
                <div
                    key={index}
                    className="search-result-item ps-2"
                    onClick={() => props.onSelect(result)}
                >
                    <span>{result.name}</span>
                </div>
            ))}
        </div>
    );
}

export default SearchResult;