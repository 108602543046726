export interface ItemDto {
    seq: number;
    imageFileSeq: number;
    imageFileUrl: string;
    standardFileSeq: number;
    standardFileUrl: string;
    checklistFileSeq: number;
    checklistFileUrl: string;
    type: string;
    code: string;
    name: string;
    lineSeq: number;
    lineName: string;
    uph: number;
    voltage: string;
    current: string;
    gasFlowRate: string;
    airPressure: string;
    inspection_1: string;
    inspection_2: string;
    inspection_3: string;
    unitCost: number;
    comment: string;
}

export const emptyItemDto: ItemDto = {
    seq: 0,
    imageFileSeq: 0,
    imageFileUrl: "",
    standardFileSeq: 0,
    standardFileUrl: "",
    checklistFileSeq: 0,
    checklistFileUrl: "",
    type: "",
    code: "",
    name: "",
    lineSeq: 0,
    lineName: "",
    uph: 0,
    voltage: "",
    current: "",
    gasFlowRate: "",
    airPressure: "",
    inspection_1: "",
    inspection_2: "",
    inspection_3: "",
    unitCost: 0,
    comment: ""
};