import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import SortableHeader from "../../../../components/table/SortableHeader";
import { WeldingPerformanceDto } from "../dto/WeldingPerformanceDto";

export interface TableDataProps {
    data: WeldingPerformanceDto[];
}

interface TotalPerformance {
    productionQuantity: number;
    defectQuantity: number;
    defectRate: number;
}

type SortState = Record<keyof WeldingPerformanceDto, boolean | null>;

function TableData(props: TableDataProps) {
    const [performances, setPerformances] = useState<WeldingPerformanceDto[]>([]);
    const [TotalPerformance, setTotalPerformance] = useState<TotalPerformance>({ productionQuantity: 0, defectQuantity: 0, defectRate: 0 });
    const [sortStates, setSortStates] = useState<SortState | null>(null);

    useEffect(() => {
        setPerformances(props.data);

        if (0 < props.data.length) {
            initSortStates();
        }
    }, [props.data]);

    useEffect(() => {
        setTotalPerformance(performances.reduce((acc, performance) => {
            acc.productionQuantity += performance.productQuantity;
            acc.defectQuantity += performance.defectQuantity;
            acc.defectRate = performances.reduce((acc, performance) => acc + performance.defectRate, 0) / performances.length;

            return acc;
        }, { productionQuantity: 0, defectQuantity: 0, defectRate: 0 } as TotalPerformance));
    }, [performances]);

    const initSortStates = () => {
        const initialStateKeys = Object.keys(props.data[0]) as (keyof WeldingPerformanceDto)[];

        setSortStates(initialStateKeys.reduce((acc, key) => {
            acc[key] = null;
            return acc;
        }, {} as SortState));
    }

    const onSort = (key: keyof WeldingPerformanceDto) => {
        const isAscending = sortStates![key] === null ? true : !sortStates![key];

        setSortStates((prev) => ({
            ...prev!,
            [key!]: isAscending
        }));

        for (const stateKey in sortStates) {
            if (stateKey !== key) {
                setSortStates((prev) => ({
                    ...prev!,
                    [stateKey]: null
                }));
            }
        }

        const sortedPerformance = [...performances].sort((a, b) => {
            if (a[key] > b[key]) {
                return isAscending ? 1 : -1;
            } else if (a[key] < b[key]) {
                return isAscending ? -1 : 1;
            } else {
                return 0;
            }
        });

        setPerformances(sortedPerformance);
    };

    return (
        <>
            <Table className="table-data w-100" hover>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>
                            <SortableHeader label="설비명" isAscending={sortStates && sortStates["lineName"]} onSort={() => onSort("lineName")} />
                        </th>
                        <th>
                            <SortableHeader label="품번" isAscending={sortStates && sortStates["itemCode"]} onSort={() => onSort("itemCode")} />
                        </th>
                        <th>
                            <SortableHeader label="품명" isAscending={sortStates && sortStates["itemName"]} onSort={() => onSort("itemName")} />
                        </th>
                        <th>
                            <SortableHeader label="실적 처리 일자" isAscending={sortStates && sortStates["workedAt"]} onSort={() => onSort("workedAt")} />
                        </th>
                        <th>
                            <SortableHeader label="생산 수량" isAscending={sortStates && sortStates["productQuantity"]} onSort={() => onSort("productQuantity")} />
                        </th>
                        <th>
                            <SortableHeader label="불량 수량" isAscending={sortStates && sortStates["defectQuantity"]} onSort={() => onSort("defectQuantity")} />
                        </th>
                        <th>
                            <SortableHeader label="불량률(%)" isAscending={sortStates && sortStates["defectRate"]} onSort={() => onSort("defectRate")} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {performances.map((performance, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{performance.lineName}</td>
                                <td>{performance.itemCode}</td>
                                <td>{performance.itemName}</td>
                                <td>{performance.workedAt}</td>
                                <td>{performance.productQuantity}</td>
                                <td>{performance.defectQuantity}</td>
                                <td>{performance.defectRate}</td>
                            </tr>
                        );
                    })}
                    {performances.length === 0 &&
                        <tr>
                            <td colSpan={8} className="text-center">데이터가 없습니다.</td>
                        </tr>
                    }
                    <tr className="table-total-row">
                        <td colSpan={5}>합계</td>
                        <td>{TotalPerformance.productionQuantity.toLocaleString()}</td>
                        <td>{TotalPerformance.defectQuantity.toLocaleString()}</td>
                        <td>{TotalPerformance.defectRate.toFixed(4)}</td>
                    </tr>
                </tbody>
            </Table>
        </>
    );
}

export default TableData;