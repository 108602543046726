import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Col, Form, Image, InputGroup, Row } from "react-bootstrap";
import searchIcon from "../../assets/images/search_icon.png";
import searchIconBlack from "../../assets/images/search_icon_black.png";
import SearchResult from "./SearchResult";
import { SearchResultDto } from "./dto/SearchResultDto";

export interface SearchInputGroupProps {
    isDialog?: boolean;
    hasFocus?: boolean;
    label: string;
    value: string;
    searchUrl: (name: string) => string;
    onClick: () => void;
    onChange: (result: SearchResultDto) => void;
    onEmpty?: (value: string) => void;
}

function SearchInputGroup(props: SearchInputGroupProps) {
    const valueRef = useRef<HTMLInputElement>(null);

    const [isAutoComplete, setIsAutoComplete] = useState<boolean>(false);
    const [isComposing, setIsComposing] = useState<boolean>(false);
    const [value, setValue] = useState<string | null>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const [searchResult, setSearchResult] = useState<SearchResultDto[]>([]);

    useEffect(() => {
        if (props.value && props.value !== "") {
            setIsAutoComplete(true);
            setValue(props.value);
        }
    }, [props.value]);

    useEffect(() => {
        if (props.hasFocus) {
            valueRef.current?.focus();
        }
    }, [props.hasFocus]);

    const search = () => {
        if (value === null || value === "") {
            props.onChange({ seq: 0, name: "" });
            setSearchResult([]);
            return;
        }

        axios.get<SearchResultDto[]>(props.searchUrl(value)).then((response) => {
            if (response.data.length === 1) {
                setIsAutoComplete(true);
                setValue(response.data[0].name);
                setSearchResult([]);

                props.onChange(response.data[0]);
            } else {
                if (response.data.length === 0) {
                    props.onEmpty && props.onEmpty(value);
                }

                setSearchResult(response.data);
            }
        }).catch(() => { });
    }

    useEffect(() => {
        if (props.isDialog) {
            return;
        }

        if (value !== "" && (value === null || isComposing || isAutoComplete)) {
            setIsAutoComplete(false);
            return;
        }

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            search();
        }, 500);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [props.isDialog, value, isComposing]);

    return (
        <>
            {props.isDialog &&
                <>
                    <Row className="align-items-center">
                        <Col xs={3}>
                            <Row className="w-100 align-items-center">
                                <Col xs={7}>
                                    {props.label}
                                </Col>
                                <Col xs={5} className="p-0" title={`${props.label} 검색`}>
                                    <Image
                                        role="button"
                                        src={searchIconBlack}
                                        height={23}
                                        onClick={() => props.onClick()}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={9}>
                            <Form.Control
                                ref={valueRef}
                                type="text"
                                className={`${searchResult.length === 0 ? "" : "has-search-result"} dialog-search-input`}
                                value={value || ""}
                                placeholder="입력 후 엔터키를 눌러주세요."
                                onChange={(e) => setValue(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        search();
                                    }
                                }}
                                onBlur={() => {
                                    search();
                                }}
                                onCompositionStart={() => setIsComposing(true)}
                                onCompositionEnd={() => {
                                    setIsAutoComplete(false);
                                    setIsComposing(false);
                                }}
                            />
                        </Col>
                    </Row>
                    {0 < searchResult.length &&
                        <Row style={{ paddingLeft: 135 }}>
                            <SearchResult
                                width={valueRef.current ? valueRef.current.getBoundingClientRect().width - 1 : 0}
                                searchResult={searchResult}
                                onSelect={(result) => {
                                    setIsAutoComplete(true);
                                    setValue(result.name);
                                    setSearchResult([]);

                                    props.onChange(result);
                                }}
                            />
                        </Row>
                    }
                </>
            }
            {!props.isDialog &&
                <>
                    <InputGroup>
                        <InputGroup.Text>
                            <Row className="ps-2 pe-2 align-items-center">
                                <Col className="p-0">
                                    {props.label}
                                </Col>
                                <Col className="p-0 ms-2" title={`${props.label} 검색`}>
                                    <Image
                                        role="button"
                                        src={searchIcon}
                                        height={23}
                                        onClick={() => props.onClick()}
                                    />
                                </Col>
                            </Row>
                        </InputGroup.Text>
                        <Form.Control
                            ref={valueRef}
                            type="text"
                            className={searchResult.length === 0 ? "" : "has-search-result"}
                            value={value || ""}
                            onChange={(e) => setValue(e.target.value)}
                            onCompositionStart={() => setIsComposing(true)}
                            onCompositionEnd={() => {
                                setIsAutoComplete(false);
                                setIsComposing(false);
                            }}
                        />
                    </InputGroup>
                    {0 < searchResult.length &&
                        <SearchResult
                            top={valueRef.current?.getBoundingClientRect().bottom}
                            left={valueRef.current?.getBoundingClientRect().left}
                            width={valueRef.current?.getBoundingClientRect().width}
                            searchResult={searchResult}
                            onSelect={(result) => {
                                setIsAutoComplete(true);
                                setValue(result.name);
                                setSearchResult([]);

                                props.onChange(result);
                            }}
                        />
                    }
                </>
            }
        </>
    );
}

export default SearchInputGroup;