import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSetRecoilState } from "recoil";
import ConfirmDialog from "../../../components/dialog/ConfirmDialog";
import { SubInfoType, getSubInfoDeleteUrl, getSubInfoName, getSubInfoUrl } from "../../../constants/SubInfoType";
import { toastState } from "../../../store/toastStore";
import CreateSubInfo from "./components/CreateSubInfo";
import Manage from "./components/Manage";
import TableData from "./components/TableData";
import { SubInfoDto } from "./dto/SubInfoDto";

export interface SubInfoProps {
    isVisible: boolean;
    subInfoType: SubInfoType;
    onSelect: (dto: SubInfoDto) => void;
    onClose: () => void;
}

function SubInfo(props: SubInfoProps) {
    const setToasts = useSetRecoilState(toastState);

    const [list, setList] = useState<SubInfoDto[]>([]);
    const [checkedSeqs, setCheckedSeqs] = useState<number[]>([]);
    const [targetDto, setTargetDto] = useState<SubInfoDto | undefined>(undefined);

    const [isCreateVisible, setIsCreateVisible] = useState<boolean>(false);
    const [isDeleteVisible, setIsDeleteVisible] = useState<boolean>(false);

    const getSubInfoList = () => {
        axios.get<SubInfoDto[]>(getSubInfoUrl(props.subInfoType)).then((response) => {
            setList(response.data);
        }).catch(() => { });
    }

    useEffect(() => {
        if (props.isVisible) {
            getSubInfoList();
        }
    }, [props.isVisible]);

    const handleDelete = () => {
        setIsDeleteVisible(false);

        axios.post(getSubInfoDeleteUrl(props.subInfoType), { seqs: checkedSeqs }).then(() => {
            setToasts((oldToasts) => [...oldToasts, {
                id: Date.now(),
                title: `${getSubInfoName(props.subInfoType)} 삭제`,
                message: "삭제되었습니다."
            }]);

            getSubInfoList();
        }).catch(() => { });
    }

    const closeCreateDialog = () => {
        setIsCreateVisible(false);
        setTargetDto(undefined);
    }

    return (
        <>
            {(!isCreateVisible && !isDeleteVisible) &&
                <Modal show={props.isVisible} onHide={() => props.onClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {getSubInfoName(props.subInfoType)} 목록
                            <span className="title-comment ms-2">
                                (※ 이름을 클릭하면 선택됩니다.)
                            </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Manage
                            totalCount={list.length}
                            onCreateClick={() => setIsCreateVisible(true)}
                            onDeleteClick={() => {
                                if (checkedSeqs.length === 0) {
                                    setToasts((oldToasts) => [...oldToasts, {
                                        id: Date.now(),
                                        title: `${getSubInfoName(props.subInfoType)} 삭제`,
                                        message: "선택된 항목이 없습니다."
                                    }]);
                                    return;
                                }

                                setIsDeleteVisible(true);
                            }}
                        />
                        <div
                            className="w-100 mt-3 ps-2 pe-2"
                            style={{
                                height: `380px`,
                                maxHeight: `380px`
                            }}
                        >
                            <div className="table-wrapper h-100 w-100">
                                <TableData
                                    data={list}
                                    onCheckedChange={(seqs) => setCheckedSeqs(seqs)}
                                    onUpdate={(dto) => {
                                        setTargetDto(dto);
                                        setIsCreateVisible(true);
                                    }}
                                    onClick={(name) => props.onSelect(name)}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            }
            {isCreateVisible &&
                <CreateSubInfo
                    subInfoType={props.subInfoType}
                    isUpdate={targetDto !== undefined}
                    dto={targetDto}
                    onCreated={() => {
                        getSubInfoList();
                        closeCreateDialog();
                    }}
                    onClose={() => closeCreateDialog()}
                />
            }
            {isDeleteVisible &&
                <ConfirmDialog
                    title={`${getSubInfoName(props.subInfoType)} 삭제`}
                    message={`선택된 ${checkedSeqs.length}개의 ${getSubInfoName(props.subInfoType)}를 삭제하시겠습니까?`}
                    onConfirm={() => handleDelete()}
                    onClose={() => setIsDeleteVisible(false)}
                />
            }
        </>
    );
}

export default SubInfo;