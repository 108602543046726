import { Modal } from "react-bootstrap";
import DaumPostcodeEmbed from "react-daum-postcode";

export interface SearchAddressProps {
    onChange: (address: string) => void;
    onClose: () => void;
}

function SearchAddress(props: SearchAddressProps) {
    const handleComplete = (data: any) => {
        const { _, roadAddress } = data;
        props.onChange(roadAddress);
    }

    return (
        <Modal show={true} onHide={() => props.onClose()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    주소 검색
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: "55vh" }}>
                <DaumPostcodeEmbed
                    className="w-100 h-100 border"
                    onComplete={handleComplete}
                />
            </Modal.Body>
        </Modal>
    );
}

export default SearchAddress;