import { useEffect, useState } from "react";
import { Button, FormCheck, Table } from "react-bootstrap";
import { SubInfoDto } from "../dto/SubInfoDto";

export interface TableDataProps {
    data: SubInfoDto[];
    onCheckedChange: (seqs: number[]) => void;
    onUpdate: (dto: SubInfoDto) => void;
    onClick: (dto: SubInfoDto) => void;
}

function TableData(props: TableDataProps) {
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [checkedSeqs, setCheckedSeqs] = useState<number[]>([]);

    const toggleAllChecked = (checked: boolean) => {
        setIsAllChecked(checked);
        setCheckedSeqs(checked ? props.data.map((item) => item.seq) : []);
    }

    useEffect(() => {
        props.onCheckedChange(checkedSeqs);
    }, [checkedSeqs]);

    return (
        <Table className="table-data w-100" hover>
            <thead>
                <tr>
                    <th>
                        <FormCheck.Input
                            type="checkbox"
                            className="table-checkbox all-check"
                            checked={isAllChecked}
                            onChange={(e) => toggleAllChecked(e.target.checked)}
                        />
                    </th>
                    <th>이름</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {props.data.map((item, index) => (
                    <tr key={index}>
                        <td>
                            <FormCheck.Input
                                type="checkbox"
                                className="table-checkbox"
                                checked={checkedSeqs.includes(item.seq)}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setCheckedSeqs([...checkedSeqs, item.seq]);
                                    } else {
                                        setCheckedSeqs(checkedSeqs.filter((seq) => seq !== item.seq));
                                    }
                                }}
                            />
                        </td>
                        <td role="button" onClick={() => props.onClick(item)}>
                            {item.name}
                        </td>
                        <td>
                            <Button
                                className="main-button"
                                onClick={() => props.onUpdate(item)}
                            >
                                수정
                            </Button>
                        </td>
                    </tr>
                ))}
                {props.data.length === 0 &&
                    <tr>
                        <td colSpan={3}>데이터가 없습니다.</td>
                    </tr>
                }
            </tbody>
        </Table>
    );
}

export default TableData;