import { useState } from "react";
import { Col, Container, Image, Row, Tab, Tabs } from "react-bootstrap";
import { useRecoilValue, useSetRecoilState } from "recoil";
import closeIcon from "../../../assets/images/close_icon.png";
import closeIconActive from "../../../assets/images/close_icon_active.png";
import ContextMenu from "../../../components/button/ContextMenu";
import { SubMenuType, getSubMenuName } from "../../../constants/SubMenuType";
import { tabState } from "../../../store/tabStore";
import Dashboard from "../../dashboard/Dashboard";
import AccountInfo from "../../info/account/AccountInfo";
import ItemInfo from "../../info/item/ItemInfo";
import Analysis from "../../status/Analysis";
import WeldingHistory from "../../welding/history/WeldingHistory";
import WeldingPerformance from "../../welding/performance/WeldingPerformance";
import "./style.css";

function Content() {
    const tabInfo = useRecoilValue(tabState);
    const setTabSate = useSetRecoilState(tabState);

    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

    const getMenuContent = (tab: SubMenuType) => {
        switch (tab) {
            case SubMenuType.DASHBOARD:
                return <Dashboard />;
            case SubMenuType.WELDING_HISTORY:
                return <WeldingHistory />;
            case SubMenuType.PERFORMANCE_BY_LINES:
                return <WeldingPerformance />;
            case SubMenuType.ANALYSIS:
                return <Analysis />;
            case SubMenuType.ACCOUNT_INFO:
                return <AccountInfo />;
            case SubMenuType.ITEM_INFO:
                return <ItemInfo />;
            default:
                return <></>;
        }
    }

    const closeTab = (tab: SubMenuType, index: number) => {
        setTabSate((prev) => {
            const newTabs = prev.tabs.filter((t) => t != tab);

            return {
                activeTab: newTabs.includes(tabInfo.activeTab) ? tabInfo.activeTab : newTabs[index] ? newTabs[index] : newTabs[index - 1],
                tabs: newTabs
            }
        });
    }

    const closeOtherTabs = (tab: SubMenuType) => {
        setTabSate((prev) => {
            return {
                activeTab: tab,
                tabs: prev.tabs.filter((t) => t == tab || t == SubMenuType.DASHBOARD)
            };
        });
    }

    const closeAllTabs = () => {
        setTabSate({
            activeTab: SubMenuType.DASHBOARD,
            tabs: [SubMenuType.DASHBOARD]
        });
    }

    return (
        <Container className="content-wrapper h-100 d-flex flex-column">
            <Tabs activeKey={tabInfo.activeTab}>
                {tabInfo.tabs.map((tab, index) => (
                    <Tab
                        key={index}
                        eventKey={tab}
                        title={
                            <ContextMenu
                                tab={tab}
                                onCloseOtherTabs={() => closeOtherTabs(tab)}
                                onCloseAllTabs={closeAllTabs}
                            >
                                <Row className="tab-title align-items-center">
                                    <Col
                                        xs={tab == SubMenuType.DASHBOARD ? 12 : 10}
                                        className="p-0"
                                        onClick={() => setTabSate((prev) => {
                                            return {
                                                ...prev,
                                                activeTab: tab
                                            };
                                        })}
                                    >
                                        {getSubMenuName(tab)}
                                    </Col>
                                    <Col
                                        xs={tab == SubMenuType.DASHBOARD ? 0 : 2}
                                        className={`p-0 tab-close ${tabInfo.activeTab == tab ? "active" : ""}`}
                                        onClick={() => closeTab(tab, index)}
                                    >
                                        {tab != SubMenuType.DASHBOARD &&
                                            <Image src={tabInfo.activeTab == tab ? closeIconActive : closeIcon} />
                                        }
                                    </Col>
                                </Row>
                            </ContextMenu>
                        }
                    >
                        {getMenuContent(tab)}
                    </Tab>
                ))}
            </Tabs>
        </Container>
    );
}

export default Content;