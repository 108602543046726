import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import Delimiter from "../../../components/delimiter/Delimiter";
import { ApiUrl } from "../../../constants/ApiUrl";
import Manage from "../history/components/Manage";
import Search from "./components/Search";
import TableData from "./components/TableData";
import { WeldingPerformanceDto } from "./dto/WeldingPerformanceDto";
import { WeldingPerformanceSearchDto } from "./dto/WeldingPerformanceSearchDto";
import saveAs from "file-saver";

function WeldingPerformance() {
    const tableRef = useRef<HTMLDivElement>(null);
    const [tableMaxHeight, setTableMaxHeight] = useState<number>(0);
    const [weldingPerformances, setWeldingPerformances] = useState<WeldingPerformanceDto[]>([]);

    const [searchDto, setSearchDto] = useState<WeldingPerformanceSearchDto | null>(null);

    useEffect(() => {
        setTimeout(() => {
            if (tableRef.current) {
                setTableMaxHeight(window.innerHeight - tableRef.current.getBoundingClientRect().top);
            }
        }, 100);
    }, []);

    const search = (dto: WeldingPerformanceSearchDto) => {
        if (dto.startDate === "" || dto.endDate === "") {
            return;
        }

        setSearchDto(dto);

        axios.get<WeldingPerformanceDto[]>(ApiUrl.WELDING_PERFORMANCE(
            dto.startDate,
            dto.endDate,
            dto.lineName
        )).then((response) => {
            setWeldingPerformances(response.data);
        }).catch(() => { });
    }

    const download = () => {
        if (searchDto === null) {
            return;
        }

        axios.get(ApiUrl.DOWNLOAD_WELDING_PERFORMANCE(
            searchDto.startDate,
            searchDto.endDate,
            searchDto.lineName
        ), { responseType: "arraybuffer" }).then((response) => {
            const blob = new Blob([response.data], { type: response.headers["content-type"] });
            
            saveAs(blob, `설비별실적집계_${new Date().toISOString().slice(0, 10).replace(/-/g, "")}.xlsx`);
        }).catch(() => { });
    }

    return (
        <Container className="content-wrapper">
            <Search onSearch={search} />
            <Delimiter />
            <Manage
                totalCount={weldingPerformances.length}
                onDownloadClick={download}
            />
            <div
                ref={tableRef}
                className="w-100 mt-3 ps-2 pe-2"
                style={{
                    height: `${tableMaxHeight}px`,
                    maxHeight: `${tableMaxHeight}px`
                }}
            >
                <div className="table-wrapper h-100 w-100">
                    <TableData data={weldingPerformances} />
                </div>
            </div>
        </Container>
    );
}

export default WeldingPerformance;