import { format } from "date-fns";
import { useState } from "react";
import { Button } from "react-bootstrap";
import DateRangeComponent from "../date/DateRangeComponent";

export interface DateRangeButtonProps {
    startDate: Date;
    endDate: Date;
    onChange: (startDate: Date, endDate: Date) => void;
}

function DateRangeButton(props: DateRangeButtonProps) {
    const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

    return (
        <>
            <Button
                className="date-button"
                onClick={() => setIsDatePickerVisible(!isDatePickerVisible)}
            >
                {format(props.startDate, "yyyy년 MM월 dd일") + "~" + format(props.endDate, "yyyy년 MM월 dd일")}
            </Button>
            <DateRangeComponent
                isModalOpen={isDatePickerVisible}
                startDate={props.startDate}
                endDate={props.endDate}
                handleChange={props.onChange}
                handleClose={() => setIsDatePickerVisible(false)}
            />
        </>
    );
}

export default DateRangeButton;