import axios from "axios";
import { format } from "date-fns";
import { FormEvent, useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, Modal, Row } from "react-bootstrap";
import { useSetRecoilState } from "recoil";
import SearchAddress from "../../../../components/address/SearchAddress";
import DateComponent from "../../../../components/date/DateComponent";
import SearchInputGroup from "../../../../components/input/SearchInputGroup";
import { AccountType, getAccountTypeName } from "../../../../constants/AccountType";
import { ApiUrl } from "../../../../constants/ApiUrl";
import { SubInfoType } from "../../../../constants/SubInfoType";
import { toastState } from "../../../../store/toastStore";
import { formatPhoneNumber } from "../../../../utils/Utils";
import SubInfo from "../../subInfo/SubInfo";
import { AccountDto, emptyAccountDto } from "../dto/AccountDto";
import DirectCreateSubInfo from "../../subInfo/components/DirectCreateSubInfo";

export interface CreateProps {
    accountDto: AccountDto | null;
    onCreated: () => void;
    onClose: () => void;
}

function Create(props: CreateProps) {
    const setToasts = useSetRecoilState(toastState);

    const [accountDto, setAccountDto] = useState<AccountDto>(emptyAccountDto);
    const [validated, setValidated] = useState(false);

    const [isSubInfoVisible, setIsSubInfoVisible] = useState<boolean>(false);
    const [subInfoType, setSubInfoType] = useState<SubInfoType>(SubInfoType.DEPARTMENT);

    const [isDirectCreateSubInfoVisible, setIsDirectCreateSubInfoVisible] = useState<boolean>(false);
    const [emptyValue, setEmptyValue] = useState<string>("");

    const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
    const [date, setDate] = useState<Date>(new Date());

    const [isSearchAddressVisible, setIsSearchAddressVisible] = useState(false);

    useEffect(() => {
        if (props.accountDto) {
            setAccountDto(props.accountDto);
            setDate(new Date(props.accountDto.joinedAt));
        }
    }, [props.accountDto]);

    const getTitle = () => {
        return `직원 ${accountDto.seq === 0 ? "추가" : "수정"}`;
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setValidated(true);

        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity()) {
            let requestDto = {
                ...accountDto,
                joinedAt: format(date, "yyyy-MM-dd")
            };

            if (accountDto.seq === 0) {
                axios.post(ApiUrl.ACCOUNT, requestDto).then(() => {
                    setToasts((oldToasts) => [...oldToasts, {
                        id: Date.now(),
                        title: getTitle(),
                        message: "추가되었습니다."
                    }]);

                    props.onCreated();
                }).catch(() => { });
            } else {
                axios.put(ApiUrl.ACCOUNT, requestDto).then(() => {
                    setToasts((oldToasts) => [...oldToasts, {
                        id: Date.now(),
                        title: getTitle(),
                        message: "수정되었습니다."
                    }]);

                    props.onCreated();
                }).catch(() => { });
            }
        }
    }

    return (
        <>
            {(!isSubInfoVisible && !isDatePickerVisible && !isSearchAddressVisible && !isDirectCreateSubInfoVisible) &&
                <Modal show={true} onHide={() => props.onClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {getTitle()}
                        </Modal.Title>
                    </Modal.Header>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Row className="align-items-center">
                                <Col xs={3}>
                                    <Form.Label>
                                        구분 *
                                    </Form.Label>
                                </Col>
                                <Col xs={9}>
                                    <Form.Select
                                        value={accountDto.type}
                                        onChange={(e) => {
                                            setAccountDto({
                                                ...accountDto,
                                                type: Number(e.target.value)
                                            });
                                        }}
                                    >
                                        <option value={AccountType.MANAGER}>
                                            {getAccountTypeName(AccountType.MANAGER)}
                                        </option>
                                        <option value={AccountType.FIELD_WORKER}>
                                            {getAccountTypeName(AccountType.FIELD_WORKER)}
                                        </option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className="mt-2"></Row>
                            <SearchInputGroup
                                isDialog={true}
                                hasFocus={!isSubInfoVisible && subInfoType === SubInfoType.DEPARTMENT}
                                label="부서"
                                value={accountDto.departmentName}
                                searchUrl={ApiUrl.SEARCH_DEPARTMENT}
                                onClick={() => {
                                    setSubInfoType(SubInfoType.DEPARTMENT);
                                    setIsSubInfoVisible(true);
                                }}
                                onChange={(result) => {
                                    setAccountDto({
                                        ...accountDto,
                                        departmentSeq: result.seq,
                                        departmentName: result.name
                                    });
                                }}
                                onEmpty={(value) => {
                                    setSubInfoType(SubInfoType.DEPARTMENT);
                                    setEmptyValue(value);
                                    setIsDirectCreateSubInfoVisible(true);
                                }}
                            />
                            <Row className="mt-2"></Row>
                            <SearchInputGroup
                                isDialog={true}
                                hasFocus={!isSubInfoVisible && subInfoType === SubInfoType.POSITION}
                                label="직급"
                                value={accountDto.positionName}
                                searchUrl={ApiUrl.SEARCH_POSITION}
                                onClick={() => {
                                    setSubInfoType(SubInfoType.POSITION);
                                    setIsSubInfoVisible(true);
                                }}
                                onChange={(result) => {
                                    setAccountDto({
                                        ...accountDto,
                                        positionSeq: result.seq,
                                        positionName: result.name
                                    });
                                }}
                                onEmpty={(value) => {
                                    setSubInfoType(SubInfoType.POSITION);
                                    setEmptyValue(value);
                                    setIsDirectCreateSubInfoVisible(true);
                                }}
                            />
                            <Row className="align-items-center mt-2">
                                <Col xs={3}>
                                    <Form.Label>
                                        이름 *
                                    </Form.Label>
                                </Col>
                                <Col xs={9}>
                                    <Form.Control
                                        type="text"
                                        required
                                        value={accountDto.name}
                                        maxLength={50}
                                        onChange={(e) => {
                                            setAccountDto({
                                                ...accountDto,
                                                name: e.target.value
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mt-2">
                                <Col xs={3}>
                                    <Form.Label>
                                        국적 *
                                    </Form.Label>
                                </Col>
                                <Col xs={9}>
                                    <Form.Control
                                        type="text"
                                        required
                                        value={accountDto.country}
                                        maxLength={50}
                                        onChange={(e) => {
                                            setAccountDto({
                                                ...accountDto,
                                                country: e.target.value
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mt-2">
                                <Col xs={3}>
                                    <Form.Label>
                                        휴대전화 *
                                    </Form.Label>
                                </Col>
                                <Col xs={9}>
                                    <Form.Control
                                        type="text"
                                        required
                                        value={accountDto.phone}
                                        placeholder="하이픈(-)을 제외한 숫자만 입력해주세요."
                                        onChange={(e) => {
                                            setAccountDto({
                                                ...accountDto,
                                                phone: formatPhoneNumber(e.target.value)
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mt-2">
                                <Col xs={3}>
                                    <Form.Label>
                                        이메일
                                    </Form.Label>
                                </Col>
                                <Col xs={9}>
                                    <Form.Control
                                        type="text"
                                        value={accountDto.email}
                                        maxLength={100}
                                        onChange={(e) => {
                                            setAccountDto({
                                                ...accountDto,
                                                email: e.target.value
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="align-items-center mt-2">
                                <Col xs={3}>
                                    <Form.Label>
                                        입사일
                                    </Form.Label>
                                </Col>
                                <Col xs={9}>
                                    <Button
                                        className="date-button w-100"
                                        onClick={() => setIsDatePickerVisible(true)}
                                    >
                                        {format(date, "yyyy년 MM월 dd일")}
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="align-items-center mt-2">
                                <Col xs={3}>
                                    <Form.Label>
                                        주소 *
                                    </Form.Label>
                                </Col>
                                <Col xs={6}>
                                    <Form.Control
                                        type="text"
                                        required
                                        value={accountDto.address}
                                        maxLength={100}
                                        onChange={(e) => {
                                            setAccountDto({
                                                ...accountDto,
                                                address: e.target.value
                                            });

                                        }}
                                    />
                                </Col>
                                <Col xs={3}>
                                    <Button className="main-button" onClick={() => setIsSearchAddressVisible(true)}>
                                        주소 검색
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="align-items-center mt-1">
                                <Col xs={3}></Col>
                                <Col xs={9}>
                                    <Form.Control
                                        type="text"
                                        value={accountDto.addressRemain}
                                        placeholder="상세 주소를 입력해주세요."
                                        maxLength={100}
                                        onChange={(e) => {
                                            setAccountDto({
                                                ...accountDto,
                                                addressRemain: e.target.value
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                            {accountDto.type === AccountType.FIELD_WORKER &&
                                <Row className="align-items-center mt-2">
                                    <Col xs={3}>
                                        <Form.Label>
                                            현장실적 <br /> 연계여부
                                        </Form.Label>
                                    </Col>
                                    <Col xs={9}>
                                        <FormCheck.Input
                                            type="checkbox"
                                            className="table-checkbox"
                                            checked={accountDto.useTablet}
                                            onChange={(e) => setAccountDto({
                                                ...accountDto,
                                                useTablet: e.target.checked
                                            })}
                                        />
                                    </Col>
                                </Row>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="submit"
                                className="main-button"
                            >
                                {`${accountDto.seq === 0 ? "추가" : "수정"}하기`}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            }
            <SubInfo
                isVisible={isSubInfoVisible}
                subInfoType={subInfoType}
                onSelect={(dto) => {
                    switch (subInfoType) {
                        case SubInfoType.DEPARTMENT:
                            setAccountDto((prev) => {
                                return {
                                    ...prev,
                                    departmentSeq: dto.seq,
                                    departmentName: dto.name
                                }
                            });
                            break;
                        case SubInfoType.POSITION:
                            setAccountDto((prev) => {
                                return {
                                    ...prev,
                                    positionSeq: dto.seq,
                                    positionName: dto.name
                                }
                            });
                            break;
                    }

                    setIsSubInfoVisible(false);
                }}
                onClose={() => setIsSubInfoVisible(false)}
            />
            {isDatePickerVisible &&
                <DateComponent
                    date={date}
                    onChange={(date) => {
                        setIsDatePickerVisible(false);
                        setDate(date);
                    }}
                    onClose={() => setIsDatePickerVisible(false)}
                />
            }
            {isSearchAddressVisible &&
                <SearchAddress
                    onChange={(address) => {
                        setAccountDto({
                            ...accountDto,
                            address: address
                        });

                        setIsSearchAddressVisible(false);
                    }}
                    onClose={() => setIsSearchAddressVisible(false)}
                />
            }
            {isDirectCreateSubInfoVisible &&
                <DirectCreateSubInfo
                    subInfoType={subInfoType}
                    value={emptyValue}
                    onCreate={(dto) => {
                        switch (subInfoType) {
                            case SubInfoType.DEPARTMENT:
                                setAccountDto((prev) => {
                                    return {
                                        ...prev,
                                        departmentSeq: dto.seq,
                                        departmentName: dto.name
                                    }
                                });
                                break;
                            case SubInfoType.POSITION:
                                setAccountDto((prev) => {
                                    return {
                                        ...prev,
                                        positionSeq: dto.seq,
                                        positionName: dto.name
                                    }
                                });
                                break;
                        }

                        setIsDirectCreateSubInfoVisible(false);
                    }}
                    onClose={() => setIsDirectCreateSubInfoVisible(false)}
                />
            }
        </>
    );
}

export default Create;