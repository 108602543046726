import axios from "axios";
import saveAs from "file-saver";
import { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useSetRecoilState } from "recoil";
import Delimiter from "../../../components/delimiter/Delimiter";
import ConfirmDialog from "../../../components/dialog/ConfirmDialog";
import { ApiUrl } from "../../../constants/ApiUrl";
import { toastState } from "../../../store/toastStore";
import Create from "./components/Create";
import Manage from "./components/Manage";
import Search from "./components/Search";
import TableData from "./components/TableData";
import { ItemDto } from "./dto/ItemDto";
import { ItemSearchDto } from "./dto/ItemSearchDto";

function ItemInfo() {
    const setToasts = useSetRecoilState(toastState);
    const tableRef = useRef<HTMLDivElement>(null);
    const [tableHeight, setTableHeight] = useState<number>(0);

    const [items, setItems] = useState<ItemDto[]>([]);
    const [searchDto, setSearchDto] = useState<ItemSearchDto | null>(null);
    const [checkedSeqs, setCheckedSeqs] = useState<number[]>([]);

    const [isCreateVisible, setIsCreateVisible] = useState<boolean>(false);
    const [isDeleteVisible, setIsDeleteVisible] = useState<boolean>(false);
    const [targetItem, setTargetItem] = useState<ItemDto | null>(null);

    useEffect(() => {
        setTimeout(() => {
            if (tableRef.current) {
                setTableHeight(window.innerHeight - tableRef.current.getBoundingClientRect().top);
            }
        }, 100);
    }, []);

    const search = (dto: ItemSearchDto) => {
        setSearchDto(dto);

        axios.get<ItemDto[]>(ApiUrl.SEARCH_ITEM(
            dto.type,
            dto.code,
            dto.name,
            dto.lineName
        )).then((response) => {
            setItems(response.data);
        }).catch(() => { });
    }

    const handleDelete = () => {
        setIsDeleteVisible(false);

        axios.post(ApiUrl.DELETE_ITEM, { seqs: checkedSeqs }).then(() => {
            setToasts((oldToasts) => [...oldToasts, {
                id: Date.now(),
                title: "품목 정보 삭제",
                message: "삭제되었습니다."
            }]);

            search(searchDto!);
        }).catch(() => { });
    }

    const download = () => {
        if (searchDto === null) {
            return;
        }

        axios.get(ApiUrl.DOWNLOAD_ITEM(
            searchDto.type,
            searchDto.code,
            searchDto.name,
            searchDto.lineName
        ), { responseType: "arraybuffer" }).then((response) => {
            const blob = new Blob([response.data], { type: response.headers["content-type"] });

            saveAs(blob, `품목정보_${new Date().toISOString().slice(0, 10).replace(/-/g, "")}.xlsx`);
        }).catch(() => { });
    }

    return (
        <>
            <Container className="content-wrapper">
                <Search onSearch={search} />
                <Delimiter />
                <Manage
                    totalCount={items.length}
                    onCreateClick={() => setIsCreateVisible(true)}
                    onDeleteClick={() => {
                        if (checkedSeqs.length === 0) {
                            setToasts((oldToasts) => [...oldToasts, {
                                id: Date.now(),
                                title: "품목 정보 삭제",
                                message: "선택된 항목이 없습니다."
                            }]);
                            return;
                        }

                        setIsDeleteVisible(true);
                    }}
                    onDownloadClick={download}
                />
                <div
                    ref={tableRef}
                    className="w-100 mt-3 ps-2 pe-2"
                    style={{
                        height: `${tableHeight}px`,
                        maxHeight: `${tableHeight}px`
                    }}
                >
                    <div className="table-wrapper h-100 w-100">
                        <TableData
                            data={items}
                            onClick={(itemDto) => {
                                setTargetItem(itemDto);
                                setIsCreateVisible(true);
                            }}
                            onCheckedChange={(seqs) => setCheckedSeqs(seqs)}
                        />
                    </div>
                </div>
            </Container>
            {isCreateVisible &&
                <Create
                    itemDto={targetItem}
                    onCreated={() => {
                        search(searchDto!);
                        setTargetItem(null);
                        setIsCreateVisible(false);
                    }}
                    onClose={() => {
                        setTargetItem(null);
                        setIsCreateVisible(false);
                    }}
                />
            }
            {isDeleteVisible &&
                <ConfirmDialog
                    title="품목 정보 삭제"
                    message={`선택된 ${checkedSeqs.length}개의 품목 정보를 삭제하시겠습니까?`}
                    onConfirm={() => handleDelete()}
                    onClose={() => setIsDeleteVisible(false)}
                />
            }
        </>
    );
}

export default ItemInfo;