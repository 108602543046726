import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DebouncedInputGroup from "../../../../components/input/DebouncedInputGroup";
import SearchInputGroup from "../../../../components/input/SearchInputGroup";
import { ApiUrl } from "../../../../constants/ApiUrl";
import { SubInfoType } from "../../../../constants/SubInfoType";
import SubInfo from "../../subInfo/SubInfo";
import { AccountSearchDto, emptyAccountSearchDto } from "../dto/AccountSearchDto";

export interface SearchProps {
    onSearch: (searchDto: AccountSearchDto) => void;
}

function Search(props: SearchProps) {
    const [isSubInfoVisible, setIsSubInfoVisible] = useState<boolean>(false);
    const [subInfoType, setSubInfoType] = useState<SubInfoType>(SubInfoType.DEPARTMENT);
    const [searchDto, setSearchDto] = useState<AccountSearchDto>(emptyAccountSearchDto);

    useEffect(() => {
        props.onSearch(searchDto);
    }, [searchDto]);

    return (
        <>
            <Row className="m-0 pt-2 h-100 align-items-center">
                <Col>
                    <SearchInputGroup
                        label="부서"
                        value={searchDto.departmentName}
                        searchUrl={ApiUrl.SEARCH_DEPARTMENT}
                        onClick={() => {
                            setSubInfoType(SubInfoType.DEPARTMENT);
                            setIsSubInfoVisible(true);
                        }}
                        onChange={(result) => {
                            setSearchDto((prev) => {
                                return {
                                    ...prev,
                                    departmentName: result.name
                                }
                            })
                        }}
                    />
                </Col>
                <Col>
                    <SearchInputGroup
                        label="직급"
                        value={searchDto.positionName}
                        searchUrl={ApiUrl.SEARCH_POSITION}
                        onClick={() => {
                            setSubInfoType(SubInfoType.POSITION);
                            setIsSubInfoVisible(true);
                        }}
                        onChange={(result) => {
                            setSearchDto((prev) => {
                                return {
                                    ...prev,
                                    positionName: result.name
                                }
                            })
                        }}
                    />
                </Col>
                <Col>
                    <DebouncedInputGroup
                        label="이름"
                        onChange={(value) => setSearchDto((prev) => {
                            return {
                                ...prev,
                                name: value
                            }
                        })}
                    />
                </Col>
                <Col>
                    <DebouncedInputGroup
                        label="국적"
                        onChange={(value) => setSearchDto((prev) => {
                            return {
                                ...prev,
                                countryName: value
                            }
                        })}
                    />
                </Col>
            </Row>
            <SubInfo
                isVisible={isSubInfoVisible}
                subInfoType={subInfoType}
                onSelect={(dto) => {
                    switch (subInfoType) {
                        case SubInfoType.DEPARTMENT:
                            setSearchDto((prev) => {
                                return {
                                    ...prev,
                                    departmentSeq: dto.seq,
                                    departmentName: dto.name
                                }
                            });
                            break;
                        case SubInfoType.POSITION:
                            setSearchDto((prev) => {
                                return {
                                    ...prev,
                                    positionSeq: dto.seq,
                                    positionName: dto.name
                                }
                            });
                            break;
                    }

                    setIsSubInfoVisible(false);
                }}
                onClose={() => setIsSubInfoVisible(false)}
            />
        </>
    );
}

export default Search;