export enum AccountType {
    MANAGER, FIELD_WORKER
}

export const getAccountTypeName = (type: AccountType) => {
    switch (type) {
        case AccountType.MANAGER:
            return "관리";
        case AccountType.FIELD_WORKER:
            return "생산";
    }
}