import { Col, Row } from "react-bootstrap";
import { useSetRecoilState } from "recoil";
import { SubMenuType, getSubMenuName } from "../../constants/SubMenuType";
import { tabState } from "../../store/tabStore";

export interface SubMenuItemProps {
    subMenuType: SubMenuType;
}

function SubMenuItem(props: SubMenuItemProps) {
    const setTabState = useSetRecoilState(tabState);

    return (
        <Row
            className="menu-item align-items-center pt-2 pb-2 ps-2"
            onClick={() => setTabState((prev) => {
                if (prev.tabs.includes(props.subMenuType)) {
                    return {
                        activeTab: props.subMenuType,
                        tabs: prev.tabs
                    };
                } else {
                    return {
                        activeTab: props.subMenuType,
                        tabs: [...prev.tabs, props.subMenuType]
                    };
                }
            })}
        >
            <Col xs={2}></Col>
            <Col xs={10}>
                - {getSubMenuName(props.subMenuType)}
            </Col>
        </Row>
    );
}

export default SubMenuItem;