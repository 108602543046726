export interface WeldingPerformanceSearchDto {
    startDate: string,
    endDate: string,
    lineName: string,
}

export const emptyWeldingPerformanceSearchDto: WeldingPerformanceSearchDto = {
    startDate: "",
    endDate: "",
    lineName: ""
}