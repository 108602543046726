import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import AreaChart from "../../../components/chart/AreaChart";
import { AreaChartData } from "../../../components/chart/AreaChartData";
import { ApiUrl } from "../../../constants/ApiUrl";

export interface MonthlyProductionProps {
    years: number[];
}

function MonthlyProduction(props: MonthlyProductionProps) {
    const [productionYears, setProductionYears] = useState<number | null>(null);
    const [monthlyProduction, setMonthlyProduction] = useState<AreaChartData[]>([]);

    const [productionQuantities, setProductionQuantities] = useState<number[]>([]);
    const [defectQuantities, setDefectQuantities] = useState<number[]>([]);

    useEffect(() => {
        if (0 < props.years.length) {
            setProductionYears(props.years[props.years.length - 1]);
        }
    }, [props.years]);

    const getMonthlyProduction = useCallback(() => {
        axios.get<AreaChartData[]>(ApiUrl.MONTHLY_PRODUCTION(productionYears!)).then((response) => {
            setMonthlyProduction(response.data);
            setTableData(response.data);
        });
    }, [productionYears]);

    const setTableData = (areaChartData: AreaChartData[]) => {
        let productionQuantities: number[] = [];
        let defectQuantities: number[] = [];

        areaChartData.forEach((chartData: AreaChartData) => {
            if (chartData.name === "생산") {
                chartData.data.forEach((data: [number, number]) => {
                    productionQuantities.push(data[1]);
                });
            } else {
                chartData.data.forEach((data: [number, number]) => {
                    defectQuantities.push(data[1]);
                });
            }
        });

        setProductionQuantities(productionQuantities);
        setDefectQuantities(defectQuantities);
    }

    useEffect(() => {
        if (productionYears) {
            getMonthlyProduction();
        }
    }, [productionYears]);

    return (
        <Row className="h-100 m-0">
            <Col className="right-border pt-2">
                <Row>
                    <Col className="p-0">
                        <AreaChart
                            title="월별 실적 수량"
                            years={props.years}
                            data={monthlyProduction}
                            height={550}
                            onChangeYear={(year) => setProductionYears(year)}
                        />
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <span className="bold-text fs-6">
                            월별 실적 데이터
                        </span>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <Table className="table-data mt-2" hover>
                            <thead>
                                <tr>
                                    <th></th>
                                    {Array.from({ length: 12 }, (_, i) => i + 1).map((month, index) => (
                                        <th key={index}>{month}월</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>생산</td>
                                    {productionQuantities.length === 0 &&
                                        <td colSpan={12} className="text-center">데이터가 없습니다.</td>
                                    }
                                    {productionQuantities.map((quantity, index) => (
                                        <td key={index}>{quantity.toLocaleString()}</td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>불량</td>
                                    {defectQuantities.length === 0 &&
                                        <td colSpan={12} className="text-center">데이터가 없습니다.</td>
                                    }
                                    {defectQuantities.map((quantity, index) => (
                                        <td key={index}>{quantity.toLocaleString()}</td>
                                    ))}
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default MonthlyProduction;