import axios from "axios";
import { FormEvent, useEffect, useState } from "react";
import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap";
import { useSetRecoilState } from "recoil";
import SearchInputGroup from "../../../../components/input/SearchInputGroup";
import { ApiUrl } from "../../../../constants/ApiUrl";
import { FileType } from "../../../../constants/FileType";
import { SubInfoType } from "../../../../constants/SubInfoType";
import { toastState } from "../../../../store/toastStore";
import SubInfo from "../../subInfo/SubInfo";
import DirectCreateSubInfo from "../../subInfo/components/DirectCreateSubInfo";
import { ItemDto, emptyItemDto } from "../dto/ItemDto";

export interface CreateProps {
    itemDto: ItemDto | null;
    onCreated: () => void;
    onClose: () => void;
}

function Create(props: CreateProps) {
    const setToasts = useSetRecoilState(toastState);

    const [itemDto, setItemDto] = useState<ItemDto>(emptyItemDto);
    const [validated, setValidated] = useState(false);

    const [isSubInfoVisible, setIsSubInfoVisible] = useState<boolean>(false);
    const [isDirectCreateSubInfoVisible, setIsDirectCreateSubInfoVisible] = useState<boolean>(false);
    const [emptyValue, setEmptyValue] = useState<string>("");

    const [itemImage, setItemImage] = useState<string | null>(null);
    const [itemImageFile, setItemImageFile] = useState<File | null>(null);
    const [standardFile, setStandardFile] = useState<File | null>(null);
    const [checklistFile, setChecklistFile] = useState<File | null>(null);

    useEffect(() => {
        if (props.itemDto) {
            setItemDto(props.itemDto);
        }
    }, [props.itemDto]);

    const getTitle = () => {
        return `품목 ${itemDto.seq === 0 ? "추가" : "수정"}`;
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, fileType: FileType) => {
        const files = e.target.files;

        if (files) {
            const file = files[0];
            const reader = new FileReader();

            if (fileType === FileType.IMAGE) {
                reader.onloadend = () => {
                    setItemImage(reader.result as string);
                }
            }

            reader.readAsDataURL(file);

            if (fileType === FileType.IMAGE) {
                setItemDto((prev) => {
                    return {
                        ...prev,
                        imageFileSeq: 0
                    }
                });

                setItemImageFile(file);
            } else if (fileType === FileType.STANDARD) {
                setItemDto((prev) => {
                    return {
                        ...prev,
                        standardFileSeq: 0
                    }
                });

                setStandardFile(file);
            } else if (fileType === FileType.CHECKLIST) {
                setItemDto((prev) => {
                    return {
                        ...prev,
                        checklistFileSeq: 0
                    }
                });

                setChecklistFile(file);
            }
        }
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setValidated(true);

        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity()) {
            const formData = new FormData();

            if (itemImageFile) {
                formData.append("imageFile", itemImageFile);
            }

            if (standardFile) {
                formData.append("standardFile", standardFile);
            }

            if (checklistFile) {
                formData.append("checklistFile", checklistFile);
            }

            formData.append("jsonDto", JSON.stringify(itemDto));

            if (itemDto.seq === 0) {
                axios.post(ApiUrl.ITEM, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then(() => {
                    setToasts((oldToasts) => [...oldToasts, {
                        id: Date.now(),
                        title: "품목 추가",
                        message: "추가되었습니다."
                    }]);

                    props.onCreated();
                }).catch(() => { });
            } else {
                axios.put(ApiUrl.ITEM, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then(() => {
                    setToasts((oldToasts) => [...oldToasts, {
                        id: Date.now(),
                        title: "품목 수정",
                        message: "수정되었습니다."
                    }]);

                    props.onCreated();
                }).catch(() => { });
            }
        }
    }

    return (
        <>
            {(!isSubInfoVisible && !isDirectCreateSubInfoVisible) &&
                <Modal show={true} onHide={props.onClose} className="big-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {getTitle()}
                        </Modal.Title>
                    </Modal.Header>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Row>
                                <Col xs={6} className="right-border pe-3">
                                    <SearchInputGroup
                                        isDialog={true}
                                        hasFocus={!isSubInfoVisible}
                                        label="LINE"
                                        value={itemDto.lineName}
                                        searchUrl={ApiUrl.SEARCH_LINE}
                                        onClick={() => {
                                            setIsSubInfoVisible(true);
                                        }}
                                        onChange={(result) => {
                                            setItemDto({
                                                ...itemDto,
                                                lineSeq: result.seq,
                                                lineName: result.name
                                            });
                                        }}
                                        onEmpty={(value) => {
                                            setEmptyValue(value);
                                            setIsDirectCreateSubInfoVisible(true);
                                        }}
                                    />
                                    <Row className="align-items-center mt-2">
                                        <Col xs={3}>
                                            이미지
                                        </Col>
                                        <Col xs={9}>
                                            <Form.Control
                                                type="file"
                                                accept="image/*"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e, FileType.IMAGE)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt-1">
                                        <Col xs={3}></Col>
                                        <Col xs={9}>
                                            <div className="image-wrapper all-border">
                                                {itemImage
                                                    ? <Image src={itemImage} />
                                                    : itemDto.imageFileUrl !== "" ?
                                                        <Image src={itemDto.imageFileUrl} />
                                                        : ""
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt-2">
                                        <Col xs={3}>
                                            분류 *
                                        </Col>
                                        <Col xs={9}>
                                            <Form.Control
                                                type="text"
                                                required
                                                value={itemDto.type}
                                                maxLength={20}
                                                onChange={(e) => {
                                                    setItemDto({
                                                        ...itemDto,
                                                        type: e.target.value
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt-2">
                                        <Col xs={3}>
                                            품번 *
                                        </Col>
                                        <Col xs={9}>
                                            <Form.Control
                                                type="text"
                                                required
                                                value={itemDto.code}
                                                maxLength={50}
                                                onChange={(e) => {
                                                    setItemDto({
                                                        ...itemDto,
                                                        code: e.target.value
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt-2">
                                        <Col xs={3}>
                                            품명 *
                                        </Col>
                                        <Col xs={9}>
                                            <Form.Control
                                                type="text"
                                                required
                                                value={itemDto.name}
                                                maxLength={50}
                                                onChange={(e) => {
                                                    setItemDto({
                                                        ...itemDto,
                                                        name: e.target.value
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt-2">
                                        <Col xs={3}>
                                            UPH *
                                        </Col>
                                        <Col xs={9}>
                                            <Form.Control
                                                type="number"
                                                required
                                                value={itemDto.uph}
                                                onChange={(e) => {
                                                    setItemDto({
                                                        ...itemDto,
                                                        uph: +e.target.value
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt-2">
                                        <Col xs={3}>
                                            전압(V) *
                                        </Col>
                                        <Col xs={9}>
                                            <Form.Control
                                                type="text"
                                                required
                                                value={itemDto.voltage}
                                                maxLength={20}
                                                onChange={(e) => {
                                                    setItemDto({
                                                        ...itemDto,
                                                        voltage: e.target.value
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt-2">
                                        <Col xs={3}>
                                            전류(A) *
                                        </Col>
                                        <Col xs={9}>
                                            <Form.Control
                                                type="text"
                                                required
                                                value={itemDto.current}
                                                maxLength={20}
                                                onChange={(e) => {
                                                    setItemDto({
                                                        ...itemDto,
                                                        current: e.target.value
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt-2">
                                        <Col xs={3}>
                                            가스유량(l/min) *
                                        </Col>
                                        <Col xs={9}>
                                            <Form.Control
                                                type="text"
                                                required
                                                value={itemDto.gasFlowRate}
                                                maxLength={20}
                                                onChange={(e) => {
                                                    setItemDto({
                                                        ...itemDto,
                                                        gasFlowRate: e.target.value
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt-2">
                                        <Col xs={3}>
                                            AIR 압력 <br /> (kgf/㎠) *
                                        </Col>
                                        <Col xs={9}>
                                            <Form.Control
                                                type="text"
                                                required
                                                value={itemDto.airPressure}
                                                maxLength={20}
                                                onChange={(e) => {
                                                    setItemDto({
                                                        ...itemDto,
                                                        airPressure: e.target.value
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={6} className="ps-3">
                                    <Row className="align-items-center">
                                        <Col xs={3}>
                                            검사항목1항 *
                                        </Col>
                                        <Col xs={9}>
                                            <Form.Control
                                                type="text"
                                                required
                                                value={itemDto.inspection_1}
                                                maxLength={100}
                                                onChange={(e) => {
                                                    setItemDto({
                                                        ...itemDto,
                                                        inspection_1: e.target.value
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt-2">
                                        <Col xs={3}>
                                            검사항목2항 *
                                        </Col>
                                        <Col xs={9}>
                                            <Form.Control
                                                type="text"
                                                required
                                                value={itemDto.inspection_2}
                                                maxLength={100}
                                                onChange={(e) => {
                                                    setItemDto({
                                                        ...itemDto,
                                                        inspection_2: e.target.value
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt-2">
                                        <Col xs={3}>
                                            검사항목3항 *
                                        </Col>
                                        <Col xs={9}>
                                            <Form.Control
                                                type="text"
                                                required
                                                value={itemDto.inspection_3}
                                                maxLength={100}
                                                onChange={(e) => {
                                                    setItemDto({
                                                        ...itemDto,
                                                        inspection_3: e.target.value
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt-2">
                                        <Col xs={3}>
                                            단가(원)
                                        </Col>
                                        <Col xs={9}>
                                            <Form.Control
                                                type="number"
                                                value={itemDto.unitCost}
                                                onChange={(e) => {
                                                    setItemDto({
                                                        ...itemDto,
                                                        unitCost: +e.target.value
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt-2">
                                        <Col xs={3}>
                                            비고
                                        </Col>
                                        <Col xs={9}>
                                            <Form.Control
                                                type="text"
                                                value={itemDto.comment}
                                                maxLength={200}
                                                onChange={(e) => {
                                                    setItemDto({
                                                        ...itemDto,
                                                        comment: e.target.value
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt-2">
                                        <Col xs={3}>
                                            작업표준서
                                        </Col>
                                        <Col xs={9}>
                                            <Form.Control
                                                type="file"
                                                accept=".pdf"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e, FileType.STANDARD)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center mt-2">
                                        <Col xs={3}>
                                            도면 일치 <br /> 체크리스트
                                        </Col>
                                        <Col xs={9}>
                                            <Form.Control
                                                type="file"
                                                accept=".pdf"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e, FileType.CHECKLIST)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="submit"
                                className="main-button"
                            >
                                {`${itemDto.seq === 0 ? "추가" : "수정"}하기`}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            }
            <SubInfo
                isVisible={isSubInfoVisible}
                subInfoType={SubInfoType.LINE}
                onSelect={(dto) => {
                    setItemDto({
                        ...itemDto,
                        lineSeq: dto.seq,
                        lineName: dto.name
                    });

                    setIsSubInfoVisible(false);
                }}
                onClose={() => setIsSubInfoVisible(false)}
            />
            {isDirectCreateSubInfoVisible &&
                <DirectCreateSubInfo
                    subInfoType={SubInfoType.LINE}
                    value={emptyValue}
                    onCreate={(dto) => {
                        setItemDto({
                            ...itemDto,
                            lineSeq: dto.seq,
                            lineName: dto.name
                        });

                        setIsDirectCreateSubInfoVisible(false);
                    }}
                    onClose={() => setIsDirectCreateSubInfoVisible(false)}
                />
            }
        </>
    );
}

export default Create;