import { ko } from "date-fns/locale";
import { Modal } from "react-bootstrap";
import { Calendar } from "react-date-range";

export interface DateProps {
    date: Date;
    onChange: (date: Date) => void;
    onClose: () => void;
}

function DateComponent(props: DateProps) {
    return (
        <Modal show={true} onHide={() => props.onClose()}>
            <Modal.Header closeButton>
                <Modal.Title>날짜 선택</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ paddingTop: 0 }}>
                <Calendar
                    className="date-wrapper coupon-date-wrapper"
                    locale={ko}
                    dateDisplayFormat="yyyy년 MM월 dd일"
                    date={props.date}
                    onChange={(date) => props.onChange(date)}
                />
            </Modal.Body>
        </Modal>
    );
}

export default DateComponent;