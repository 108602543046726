import axios from "axios";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Main from "./pages/main/Main";
import SignIn from "./pages/signIn/SignIn";
import { keyState } from "./store/keyStore";
import CommonToast from "./components/toast/CommonToast";

axios.defaults.withCredentials = true;

function App() {
    const key = useRecoilValue(keyState);
    const setKey = useSetRecoilState(keyState);

    const [isInit, setIsInit] = useState(false);

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");

        if (accessToken) {
            setKey({ accessToken: accessToken });
        }

        setIsInit(true);
    }, []);

    useEffect(() => {
        axios.interceptors.request.clear();
        axios.interceptors.response.clear();

        axios.interceptors.request.use(
            (config) => {
                config.headers["Authorization"] = key.accessToken;

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            (response) => {
                const accessToken = response.headers["authorization"];

                if (accessToken) {
                    setKey({ accessToken: accessToken });
                    localStorage.setItem("accessToken", accessToken);
                }

                return response;
            },
            (error) => {
                if (error.response.status === 401 || error.response.status === 403) {
                    setKey({ accessToken: null });
                    localStorage.removeItem("accessToken");
                }

                return Promise.reject(error);
            }
        );
    }, [key.accessToken, setKey]);

    return (
        <>
            {isInit &&
                key.accessToken ? <Main /> : <SignIn />
            }
            <CommonToast />
        </>
    );
}

export default App;
