export interface AccountSearchDto {
    departmentName: string,
    positionName: string,
    name: string,
    countryName: string
}

export const emptyAccountSearchDto: AccountSearchDto = {
    departmentName: "",
    positionName: "",
    name: "",
    countryName: ""
}