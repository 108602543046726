import { atom } from "recoil";

export interface ToastState {
    id: number;
    title: string;
    message: string;
}

export const toastState = atom<ToastState[]>({
    key: "toastState",
    default: []
});