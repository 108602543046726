import axios from "axios";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSetRecoilState } from "recoil";
import BigDelimiter from "../../../components/delimiter/BigDelimiter";
import ChangePassword from "../../../components/dialog/ChangePassword";
import MenuItem from "../../../components/menu/MenuItem";
import SubMenuItem from "../../../components/menu/SubMenuItem";
import { ApiUrl } from "../../../constants/ApiUrl";
import { menuTypes } from "../../../constants/MenuType";
import { getSubMenuTypes } from "../../../constants/SubMenuType";
import { keyState } from "../../../store/keyStore";
import "./style.css";

function Menu() {
    const setKey = useSetRecoilState(keyState);
    const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);

    const signOut = () => {
        axios.get(ApiUrl.SIGN_OUT).then(() => {
            setKey({ accessToken: null });
            localStorage.removeItem("accessToken");
        }).catch();
    }

    return (
        <>
            <Container className="d-flex flex-column justify-content-between h-100">
                <Row className="mt-3 mb-3">
                    <Col className="text-center">
                        <span className="bold-text">
                            로봇용접생산관리
                        </span>
                    </Col>
                </Row>
                {menuTypes.map((menuType, index) => (
                    <div key={index}>
                        <BigDelimiter />
                        <MenuItem key={menuType} menuType={menuType} />
                        {getSubMenuTypes(menuType).map((subMenuType) => (
                            <SubMenuItem key={subMenuType} subMenuType={subMenuType} />
                        ))}
                    </div>
                ))}
                <BigDelimiter />
                <div className="mt-auto pb-3">
                    <BigDelimiter />
                    <Row className="menu-item pt-2 pb-2" onClick={() => setIsChangePasswordVisible(!isChangePasswordVisible)}>
                        <Col className="text-center fs-6">
                            비밀번호 변경
                        </Col>
                    </Row>
                    <Row className="menu-item pt-2 pb-2" onClick={() => signOut()}>
                        <Col className="text-center">
                            로그아웃
                        </Col>
                    </Row>
                </div>
            </Container>
            {isChangePasswordVisible &&
                <ChangePassword onClose={() => setIsChangePasswordVisible(false)} />
            }
        </>
    );
}

export default Menu;