import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PieChart from "../../components/chart/PieChart";
import { ApiUrl } from "../../constants/ApiUrl";
import MonthlyProduction from "./components/MonthlyProduction";

function Analysis() {
    const [years, setYears] = useState<number[]>([]);

    useEffect(() => {
        axios.get(ApiUrl.YEAR_RANGE).then((response) => {
            setYears(response.data);
        }).catch(() => { });
    }, []);

    return (
        <Container className="content-wrapper h-100">
            <Row className="h-100 m-0">
                <Col xs={7} className="p-0">
                    <MonthlyProduction years={years} />
                </Col>
                <Col>
                    <Row className="h-50 m-0 bottom-border">
                        <Col className="p-0">
                            <PieChart
                                title="LINE별 불량률"
                                tableHeader="LINE 이름"
                                years={years}
                                url={ApiUrl.LINE_DEFECT_RATE}
                            />
                        </Col>
                    </Row>
                    <Row className="h-50 m-0">
                        <Col className="p-0">
                            <PieChart
                                title="품목별 불량률"
                                tableHeader="품목 이름"
                                years={years}
                                url={ApiUrl.ITEM_DEFECT_RATE}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Analysis;