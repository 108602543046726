import { atom } from "recoil";
import { SubMenuType } from "../constants/SubMenuType";

export interface TabState {
    activeTab: SubMenuType;
    tabs: SubMenuType[];
}

export const tabState = atom<TabState>({
    key: "tabState",
    default: {
        activeTab: SubMenuType.DASHBOARD,
        tabs: [SubMenuType.DASHBOARD]
    }
});