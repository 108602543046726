import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Calendar from "../../components/calendar/Calendar";
import AreaChart from "../../components/chart/AreaChart";
import { ApiUrl } from "../../constants/ApiUrl";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { keyState } from "../../store/keyStore";
import { tabState } from "../../store/tabStore";
import { SubMenuType } from "../../constants/SubMenuType";

function Dashboard() {
    const tabInfo = useRecoilValue(tabState);
    const setKey = useSetRecoilState(keyState);

    const [productionYears, setProductionYears] = useState<number | null>(null);
    const [workTimeYears, setWorkTimeYears] = useState<number | null>(null);

    const [years, setYears] = useState<number[]>([]);
    const [monthlyProduction, setMonthlyProduction] = useState([]);
    const [monthlyWorkTime, setMonthlyWorkTime] = useState([]);

    useEffect(() => {
        if (tabInfo.activeTab == SubMenuType.DASHBOARD) {
            getYearRange();
        }
    }, [tabInfo.activeTab]);

    const getYearRange = () => {
        axios.get(ApiUrl.YEAR_RANGE).then((response) => {
            setYears(response.data);

            setProductionYears(response.data[response.data.length - 1]);
            setWorkTimeYears(response.data[response.data.length - 1]);
        }).catch(() => {
            setKey({ accessToken: null });
            localStorage.removeItem("accessToken");
        });
    }

    const getMonthlyProduction = useCallback(() => {
        axios.get(ApiUrl.MONTHLY_PRODUCTION(productionYears!)).then((response) => {
            setMonthlyProduction(response.data);
        });
    }, [productionYears]);

    const getMonthlyWorkTime = useCallback(() => {
        axios.get(ApiUrl.MONTHLY_WORK_TIME(workTimeYears!)).then((response) => {
            setMonthlyWorkTime(response.data);
        });
    }, [workTimeYears]);

    useEffect(() => {
        if (productionYears) {
            getMonthlyProduction();
        }
    }, [productionYears]);

    useEffect(() => {
        if (workTimeYears) {
            getMonthlyWorkTime();
        }
    }, [workTimeYears]);

    return (
        <Container style={{ minWidth: "100%", height: "100%" }}>
            <Row className="h-100">
                <Col xs={8} className="right-border">
                    <Row className="h-50 bottom-border">
                        <Col className="p-0">
                            <AreaChart
                                title="월별 실적 수량"
                                years={years}
                                data={monthlyProduction}
                                height={350}
                                onChangeYear={(year) => setProductionYears(year)}
                            />
                        </Col>
                    </Row>
                    <Row className="h-50">
                        <Col className="p-0">
                            <AreaChart
                                title="월별 투입 M/R"
                                years={years}
                                data={monthlyWorkTime}
                                height={350}
                                onChangeYear={(year) => setWorkTimeYears(year)}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={4}>
                    <Row className="mt-2">
                        <Col>
                            <span className="bold-text fs-6">
                                달력
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Calendar />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Dashboard;