import axios from "axios";
import { FormEvent, useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ApiUrl } from "../../constants/ApiUrl";
import { ChangePasswordDto } from "./dto/ChangePasswordDto";
import { useSetRecoilState } from "recoil";
import { toastState } from "../../store/toastStore";

export interface ChangePasswordProps {
    onClose: () => void;
}

function ChangePassword(props: ChangePasswordProps) {
    const setToasts = useSetRecoilState(toastState);
    const passwordRef = useRef<HTMLInputElement>(null);

    const [validated, setValidated] = useState(false);
    const [changePasswordDto, setChangePasswordDto] = useState<ChangePasswordDto>({ password: "", newPassword: "" });
    const [failMessage, setFailMessage] = useState<string | null>(null);

    useEffect(() => {
        passwordRef.current?.focus();
    }, []);

    const isValidPassword = () => {
        const regExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{10,}$/;
        return regExp.test(changePasswordDto.newPassword);
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setValidated(true);

        if (!isValidPassword()) {
            setFailMessage("영문/숫자/특문 포함 10자 이상으로 입력해 주세요.");
            return;
        }

        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity()) {
            axios.post(ApiUrl.CHANGE_PASSWORD, changePasswordDto).then(() => {
                setToasts((oldToasts) => [...oldToasts, {
                    id: Date.now(),
                    title: "비밀번호 변경",
                    message: "비밀번호가 성공적으로 변경되었습니다."
                }]);

                props.onClose();
            }).catch(() => {
                setFailMessage("비밀번호가 일치하지 않습니다.");
            });
        }
    }

    return (
        <Modal show={true} onHide={() => props.onClose()}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        비밀번호 변경
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label className="fw-semibold">
                            현재 비밀번호
                        </Form.Label>
                        <Form.Control
                            ref={passwordRef}
                            type="password"
                            placeholder="현재 비밀번호를 입력해 주세요."
                            required
                            onChange={(e) => { setChangePasswordDto({ ...changePasswordDto, password: e.target.value }) }}
                        />
                    </Form.Group>
                    <Form.Group className="mt-4">
                        <Form.Label className="fw-semibold">
                            새 비밀번호 (영문/숫자/특문 포함 10자 이상)
                        </Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="새 비밀번호를 입력해 주세요."
                            required
                            onChange={(e) => { setChangePasswordDto({ ...changePasswordDto, newPassword: e.target.value }) }}
                        />
                    </Form.Group>
                    {failMessage &&
                        <div className="mt-1 text-danger">
                            ※ {failMessage}
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" className="w-100 main-button">
                        변경하기
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default ChangePassword;