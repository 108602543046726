import { useEffect, useState } from "react";
import { Button, FormCheck, Image, Modal, Table } from "react-bootstrap";
import SortableHeader from "../../../../components/table/SortableHeader";
import { ItemDto } from "../dto/ItemDto";

export interface TableDataProps {
    data: ItemDto[];
    onClick: (item: ItemDto) => void;
    onCheckedChange: (seqs: number[]) => void;
}

type SortState = Record<keyof ItemDto, boolean | null>;

function TableData(props: TableDataProps) {
    const [items, setItems] = useState<ItemDto[]>([]);
    const [sortStates, setSortStates] = useState<SortState | null>(null);

    const [isAllChecked, setIsAllChecked] = useState(false);
    const [checkedSeqs, setCheckedSeqs] = useState<number[]>([]);

    const [isOriginalImageVisible, setIsOriginalImageVisible] = useState<boolean>(false);
    const [imageFileUrl, setImageFileUrl] = useState<string>("");

    useEffect(() => {
        setItems(props.data);

        if (0 < props.data.length) {
            initSortStates();
        }
    }, [props.data]);

    const initSortStates = () => {
        const initialStateKeys = Object.keys(props.data[0]) as (keyof ItemDto)[];

        setSortStates(initialStateKeys.reduce((acc, key) => {
            acc[key] = null;
            return acc;
        }, {} as SortState));
    }

    const onSort = (key: keyof ItemDto) => {
        const isAscending = sortStates![key] === null ? true : !sortStates![key];

        setSortStates((prev) => ({
            ...prev!,
            [key!]: isAscending
        }));

        for (const stateKey in sortStates) {
            if (stateKey !== key) {
                setSortStates((prev) => ({
                    ...prev!,
                    [stateKey]: null
                }));
            }
        }

        const sortedItems = [...items].sort((a, b) => {
            if (a[key] > b[key]) {
                return isAscending ? 1 : -1;
            } else if (a[key] < b[key]) {
                return isAscending ? -1 : 1;
            } else {
                return 0;
            }
        });

        setItems(sortedItems);
    }

    useEffect(() => {
        props.onCheckedChange(checkedSeqs);
    }, [checkedSeqs]);

    const toggleAllChecked = (checked: boolean) => {
        setIsAllChecked(checked);
        setCheckedSeqs(checked ? props.data.map((item) => item.seq) : []);
    }

    return (
        <>
            <Table className="long-table-data single-header" style={{ minWidth: "100%" }} hover>
                <thead>
                    <tr>
                        <th className="fixed-column">
                            <FormCheck.Input
                                type="checkbox"
                                className="table-checkbox all-check"
                                checked={isAllChecked}
                                onChange={(e) => toggleAllChecked(e.target.checked)}
                            />
                        </th>
                        <th className="fixed-column">이미지</th>
                        <th>
                            <SortableHeader label="분류" isAscending={sortStates && sortStates["type"]} onSort={() => onSort("type")} />
                        </th>
                        <th>
                            <SortableHeader label="품번" isAscending={sortStates && sortStates["code"]} onSort={() => onSort("code")} />
                        </th>
                        <th>
                            <SortableHeader label="품명" isAscending={sortStates && sortStates["name"]} onSort={() => onSort("name")} />
                        </th>
                        <th>
                            <SortableHeader label="LINE" isAscending={sortStates && sortStates["lineName"]} onSort={() => onSort("lineName")} />
                        </th>
                        <th>
                            <SortableHeader label="UPH" isAscending={sortStates && sortStates["uph"]} onSort={() => onSort("uph")} />
                        </th>
                        <th>
                            <SortableHeader label="전압(V)" isAscending={sortStates && sortStates["voltage"]} onSort={() => onSort("voltage")} />
                        </th>
                        <th>
                            <SortableHeader label="전류(A)" isAscending={sortStates && sortStates["current"]} onSort={() => onSort("current")} />
                        </th>
                        <th>
                            <SortableHeader label="가스유량(l/min)" isAscending={sortStates && sortStates["gasFlowRate"]} onSort={() => onSort("gasFlowRate")} />
                        </th>
                        <th>
                            <SortableHeader label="AIR 압력(kgf/㎠)" isAscending={sortStates && sortStates["airPressure"]} onSort={() => onSort("airPressure")} />
                        </th>
                        <th>
                            <SortableHeader label="검사항목 1항" isAscending={sortStates && sortStates["inspection_1"]} onSort={() => onSort("inspection_1")} />
                        </th>
                        <th>
                            <SortableHeader label="검사항목 2항" isAscending={sortStates && sortStates["inspection_2"]} onSort={() => onSort("inspection_2")} />
                        </th>
                        <th>
                            <SortableHeader label="검사항목 3항" isAscending={sortStates && sortStates["inspection_3"]} onSort={() => onSort("inspection_3")} />
                        </th>
                        <th>
                            <SortableHeader label="단가(원)" isAscending={sortStates && sortStates["unitCost"]} onSort={() => onSort("unitCost")} />
                        </th>
                        <th>
                            <SortableHeader label="비고" isAscending={sortStates && sortStates["comment"]} onSort={() => onSort("comment")} />
                        </th>
                        <th>작업 표준서</th>
                        <th>체크리스트</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(item => {
                        return (
                            <tr
                                key={item.seq}
                                role="button"
                                onClick={(e) => {
                                    if ((e.target as HTMLInputElement).classList.contains("not-tr-click")) {
                                        return;
                                    }

                                    props.onClick(item);
                                }}
                            >
                                <td>
                                    <FormCheck.Input
                                        type="checkbox"
                                        className="table-checkbox not-tr-click"
                                        checked={checkedSeqs.includes(item.seq)}
                                        onChange={(e) => {
                                            const checked = e.target.checked;

                                            if (checked) {
                                                setCheckedSeqs((prev) => [...prev, item.seq]);
                                            } else {
                                                setCheckedSeqs((prev) => prev.filter((seq) => seq !== item.seq));
                                            }
                                        }}
                                    />
                                </td>
                                <td>
                                    {item.imageFileUrl !== ""
                                        ? <Image
                                            className="not-tr-click"
                                            src={item.imageFileUrl}
                                            height={80}
                                            onClick={() => {
                                                setImageFileUrl(item.imageFileUrl);
                                                setIsOriginalImageVisible(true);
                                            }}
                                        />
                                        : <span className="not-tr-click">없음</span>
                                    }
                                </td>
                                <td>{item.type}</td>
                                <td>{item.code}</td>
                                <td>{item.name}</td>
                                <td>{item.lineName}</td>
                                <td>{item.uph}</td>
                                <td>{item.voltage}</td>
                                <td>{item.current}</td>
                                <td>{item.gasFlowRate}</td>
                                <td>{item.airPressure}</td>
                                <td>{item.inspection_1}</td>
                                <td>{item.inspection_2}</td>
                                <td>{item.inspection_3}</td>
                                <td>{item.unitCost}</td>
                                <td
                                    className="text-ellipsis"
                                    style={{ maxWidth: "200px" }}
                                    title={item.comment}
                                >
                                    {item.comment === "" ? "-" : item.comment}
                                </td>
                                <td className="not-tr-click">
                                    {item.standardFileUrl !== ""
                                        ? <Button
                                            className="main-button not-tr-click"
                                            onClick={() => {
                                                if (item.standardFileUrl !== "") {
                                                    window.open(item.standardFileUrl);
                                                }
                                            }}
                                        >
                                            파일 보기
                                        </Button>
                                        : <span className="not-tr-click">없음</span>
                                    }
                                </td>
                                <td className="not-tr-click">
                                    {item.checklistFileUrl !== ""
                                        ? <Button
                                            className="main-button not-tr-click"
                                            onClick={() => {
                                                if (item.checklistFileUrl !== "") {
                                                    window.open(item.checklistFileUrl);
                                                }
                                            }}
                                        >
                                            파일 보기
                                        </Button>
                                        : <span className="not-tr-click">없음</span>
                                    }
                                </td>
                            </tr>
                        );
                    })}
                    {items.length === 0 &&
                        <tr>
                            <td colSpan={18} className="text-center">데이터가 없습니다.</td>
                        </tr>
                    }
                </tbody>
            </Table>
            {isOriginalImageVisible &&
                <Modal show={true} onHide={() => setIsOriginalImageVisible(false)} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>원본 이미지</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <Image src={imageFileUrl} fluid />
                    </Modal.Body>
                </Modal>
            }
        </>
    );
}

export default TableData;