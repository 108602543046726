import axios from "axios";
import { FormEvent, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import companyLogo from "../../assets/images/company_logo.png";
import { ApiUrl } from "../../constants/ApiUrl";
import { SignInDto, emptySignInDto } from "./dto/SignInDto";

function SignIn() {
    const [validated, setValidated] = useState(false);
    const [signInDto, setSignInDto] = useState<SignInDto>(emptySignInDto);
    const [isFailed, setIsFailed] = useState(false);

    const idRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        idRef.current?.focus();
    }, []);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setValidated(true);

        const form = e.currentTarget as HTMLFormElement;
        if (form.checkValidity()) {
            axios.post(ApiUrl.SIGN_IN, signInDto).then().catch(() => {
                setIsFailed(true);
            });
        }
    }

    return (
        <Container className="h-100" style={{ maxWidth: "500px" }}>
            <Row className="h-100 align-items-center">
                <Col>
                    <Row>
                        <Col className="text-center">
                            <h3 className="fw-bold">로봇 용접 생산 관리 시스템</h3>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="text-center">
                            <h4>로그인</h4>
                        </Col>
                    </Row>
                    <Form className="mt-5" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label className="fw-semibold">
                                아이디
                            </Form.Label>
                            <Form.Control
                                ref={idRef}
                                type="text"
                                placeholder="아이디를 입력해 주세요."
                                required
                                value={signInDto.accountId}
                                onChange={(e) => setSignInDto({ ...signInDto, accountId: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mt-4">
                            <Form.Label className="fw-semibold">
                                비밀번호
                            </Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="비밀번호를 입력해 주세요."
                                required
                                value={signInDto.password}
                                onChange={(e) => setSignInDto({ ...signInDto, password: e.target.value })}
                            />
                        </Form.Group>
                        {isFailed &&
                            <div className="mt-1 text-danger">
                                ※ 아이디 또는 비밀번호가 일치하지 않습니다.
                            </div>
                        }
                        <Button type="submit" className="w-100 mt-5 main-button fs-4">
                            로그인
                        </Button>
                    </Form>
                    <Row className="mt-3">
                        <Col className="text-center">
                            <Image src={companyLogo} height="50" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default SignIn;