import { Toast, ToastContainer } from "react-bootstrap";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { toastState } from "../../store/toastStore";

function CommonToast() {
    const setToasts = useSetRecoilState(toastState);
    const toasts = useRecoilValue(toastState);

    return (
        <ToastContainer position="bottom-end">
            {toasts.map((toast) => (
                <Toast
                    className="toast-wrapper"
                    key={toast.id}
                    onClose={() => setToasts((oldToasts) => oldToasts.filter((oldToast) => oldToast.id !== toast.id))}
                    delay={2000}
                    autohide={true}
                >
                    <Toast.Header>
                        <strong className="mr-auto">
                            {toast.title}
                        </strong>
                    </Toast.Header>
                    <Toast.Body>
                        {toast.message}
                    </Toast.Body>
                </Toast>
            ))}
        </ToastContainer>
    );
}

export default CommonToast;