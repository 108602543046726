import { Button, Col, Row } from "react-bootstrap";

export interface ManageProps {
    totalCount: number;
    onDownloadClick: () => void;
}

function Manage(props: ManageProps) {
    return (
        <Row className="m-0 h-100 align-items-center">
            <Col>
                총 {props.totalCount}개
            </Col>
            <Col className="text-end">
                <Button className="main-button" onClick={() => props.onDownloadClick()}>
                    다운로드
                </Button>
            </Col>
        </Row>
    );
}

export default Manage;