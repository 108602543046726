import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Col, Container, Form, Row } from "react-bootstrap";
import { AreaChartData } from "./AreaChartData";
import { koLocale } from "./ChartLocale";

export interface AreaChartProps {
    title: string;
    years: number[];
    data: AreaChartData[];
    height: number;
    onChangeYear: (year: number) => void;
}

function AreaChart(props: AreaChartProps) {
    const [selectedYear, setSelectedYear] = useState<number>(0);

    useEffect(() => {
        setSelectedYear(props.years[props.years.length - 1]);
    }, [props.years]);

    return (
        <Container className="wrapper">
            <Row className="mt-2 w-25 align-items-center">
                <Col>
                    <span className="bold-text fs-6">
                        {props.title}
                    </span>
                </Col>
                <Col>
                    <Form.Select
                        value={selectedYear}
                        onChange={(e) => {
                            const year = Number(e.target.value);
                            setSelectedYear(year);
                            props.onChangeYear(year);
                        }}
                    >
                        {props.years.length === 0 &&
                            <option value={0}>None</option>
                        }
                        {0 < props.years.length && props.years.map((year, index) => (
                            <option key={index} value={year}>{year}</option>
                        ))}
                    </Form.Select>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <ReactApexChart
                        type="area"
                        height={props.height}
                        series={props.data}
                        options={{
                            chart: {
                                stacked: false,
                                defaultLocale: "ko",
                                locales: [koLocale]
                            },
                            colors: ['#008FFB', '#00d5fb'],
                            legend: {
                                position: 'top',
                                horizontalAlign: 'left'
                            },
                            xaxis: {
                                type: "datetime",
                                min: new Date(selectedYear, 0).getTime()
                            },
                            tooltip: {
                                x: {
                                    format: "MMMM"
                                }
                            }
                        }}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default AreaChart;