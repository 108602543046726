import weldingMenu from "../assets/images/welding_menu.png";
import weldingMenuHover from "../assets/images/welding_menu_hover.png";
import dashboardMenu from "../assets/images/dashboard_menu.png";
import dashboardMenuHover from "../assets/images/dashboard_menu_hover.png";
import infoMenu from "../assets/images/info_menu.png";
import infoMenuHover from "../assets/images/info_menu_hover.png";

export enum MenuType {
    WELDING, DASHBOARD, INFO
}

export const menuTypes = [MenuType.WELDING, MenuType.DASHBOARD, MenuType.INFO];

export const getMenuName = (menuType: MenuType) => {
    switch (menuType) {
        case MenuType.WELDING:
            return "용접 관리";
        case MenuType.DASHBOARD:
            return "현황 관리";
        case MenuType.INFO:
            return "기초 정보";
    }
}

export const getMenuImage = (menuType: MenuType) => {
    switch (menuType) {
        case MenuType.WELDING:
            return weldingMenu;
        case MenuType.DASHBOARD:
            return dashboardMenu;
        case MenuType.INFO:
            return infoMenu;
    }
}

export const getMenuHoverImage = (menuType: MenuType) => {
    switch (menuType) {
        case MenuType.WELDING:
            return weldingMenuHover;
        case MenuType.DASHBOARD:
            return dashboardMenuHover;
        case MenuType.INFO:
            return infoMenuHover;
    }
}