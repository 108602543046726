export interface AccountDto {
    seq: number,
    type: number,
    name: string,
    departmentSeq: number,
    departmentName: string,
    positionSeq: number,
    positionName: string,
    country: string,
    phone: string,
    email: string,
    joinedAt: string,
    monthOfService: number,
    address: string,
    addressRemain: string,
    useTablet: boolean
}

export const emptyAccountDto: AccountDto = {
    seq: 0,
    type: 0,
    name: "",
    departmentSeq: 0,
    departmentName: "",
    positionSeq: 0,
    positionName: "",
    country: "",
    phone: "",
    email: "",
    joinedAt: "",
    monthOfService: 0,
    address: "",
    addressRemain: "",
    useTablet: false
}