import { addDays, addMonths, endOfMonth, endOfWeek, format, isSameDay, isSameMonth, startOfMonth, startOfWeek, subMonths } from 'date-fns';
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import "./style.css";

interface CalendarProps {
    onDateChange?: (date: Date) => void;
}

const Calendar: React.FC<CalendarProps> = ({ onDateChange }) => {
    const [currentMonth, setCurrentMonth] = useState<Date>(new Date());

    const renderHeader = () => {
        const dateFormat = "yyyy년 MM월";
        return (
            <div className="calendar-header d-flex justify-content-center align-items-center">
                <FaChevronLeft onClick={prevMonth} className="me-4" />
                <span>{format(currentMonth, dateFormat)}</span>
                <FaChevronRight onClick={nextMonth} className="ms-4" />
            </div>
        );
    };

    const renderDays = () => {
        const daysOfWeek = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];

        return (
            <tr className="days-header">
                {daysOfWeek.map((day, index) => (
                    <th key={index} className="text-center days">{day}</th>
                ))}
            </tr>
        );
    };

    const renderCells = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, "d");
                const cloneDay = day;

                days.push(
                    <td
                        key={day.toString()}
                        className={
                            `text-start
                            ${!isSameMonth(day, monthStart) ? "disabled-day" : isSameDay(day, new Date()) ? "today" : ""}`
                        }
                    >
                        <span>{formattedDate}</span>
                    </td>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <tr key={day.toString()}>{days}</tr>
            );
            days = [];
        }

        return <tbody>{rows}</tbody>;
    };

    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    const prevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };

    return (
        <div className="calendar">
            {renderHeader()}
            <Table bordered hover>
                <thead>{renderDays()}</thead>
                {renderCells()}
            </Table>
        </div>
    );
};

export default Calendar;
