import axios from "axios";
import { saveAs } from "file-saver";
import { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import Delimiter from "../../../components/delimiter/Delimiter";
import { ApiUrl } from "../../../constants/ApiUrl";
import Manage from "./components/Manage";
import Search from "./components/Search";
import TableData from "./components/TableData";
import { WeldingHistoryDto } from "./dto/WeldingHistoryDto";
import { WeldingHistorySearchDto } from "./dto/WeldingHistorySearchDto";

function WeldingHistory() {
    const tableRef = useRef<HTMLDivElement>(null);
    const [tableHeight, setTableHeight] = useState<number>(0);
    const [weldingHistories, setWeldingHistories] = useState<WeldingHistoryDto[]>([]);

    const [searchDto, setSearchDto] = useState<WeldingHistorySearchDto | null>(null);

    useEffect(() => {
        setTimeout(() => {
            if (tableRef.current) {
                setTableHeight(window.innerHeight - tableRef.current.getBoundingClientRect().top);
            }
        }, 100);
    }, []);

    const search = (dto: WeldingHistorySearchDto) => {
        if (dto.startDate === "" || dto.endDate === "") {
            return;
        }

        setSearchDto(dto);

        axios.get<WeldingHistoryDto[]>(ApiUrl.WELDING_HISTORY(
            dto.startDate,
            dto.endDate,
            dto.lineName,
            dto.itemCode,
            dto.itemName,
            dto.accountName
        )).then((response) => {
            setWeldingHistories(response.data);
        }).catch(() => { });
    }

    const download = () => {
        if (searchDto === null) {
            return;
        }

        axios.get(ApiUrl.DOWNLOAD_WELDING_HISTORY(
            searchDto.startDate,
            searchDto.endDate,
            searchDto.lineName,
            searchDto.itemCode,
            searchDto.itemName,
            searchDto.accountName
        ), { responseType: "arraybuffer" }).then((response) => {
            const blob = new Blob([response.data], { type: response.headers["content-type"] });

            saveAs(blob, `용접일보_${new Date().toISOString().slice(0, 10).replace(/-/g, "")}.xlsx`);
        }).catch(() => { });
    }

    return (
        <Container className="content-wrapper">
            <Search onSearch={search} />
            <Delimiter />
            <Manage
                totalCount={weldingHistories.length}
                onDownloadClick={download}
            />
            <div
                ref={tableRef}
                className="w-100 mt-3 ps-2 pe-2"
                style={{
                    height: `${tableHeight}px`,
                    maxHeight: `${tableHeight}px`
                }}
            >
                <div className="table-wrapper h-100 w-100">
                    <TableData data={weldingHistories} />
                </div>
            </div>
        </Container>
    );
}

export default WeldingHistory;