import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import SortableHeader from "../../../../components/table/SortableHeader";
import { WeldingHistoryDto } from "../dto/WeldingHistoryDto";
import WeldingHistory from "../WeldingHistory";

export interface TableDataProps {
    data: WeldingHistoryDto[];
}

type SortState = Record<keyof WeldingHistoryDto, boolean | null>;

function TableData(props: TableDataProps) {
    const [histories, setHistories] = useState<WeldingHistoryDto[]>([]);
    const [sortStates, setSortStates] = useState<SortState | null>(null);

    useEffect(() => {
        setHistories(props.data);

        if (0 < props.data.length) {
            initSortStates();
        }
    }, [props.data]);

    const initSortStates = () => {
        const initialStateKeys = Object.keys(props.data[0]) as (keyof WeldingHistoryDto)[];

        setSortStates(initialStateKeys.reduce((acc, key) => {
            acc[key] = null;
            return acc;
        }, {} as SortState));
    }

    const onSort = (key: keyof WeldingHistoryDto) => {
        const isAscending = sortStates![key] === null ? true : !sortStates![key];

        setSortStates((prev) => ({
            ...prev!,
            [key!]: isAscending
        }));

        for (const stateKey in sortStates) {
            if (stateKey !== key) {
                setSortStates((prev) => ({
                    ...prev!,
                    [stateKey]: null
                }));
            }
        }

        const sortedHistories = [...histories].sort((a, b) => {
            if (a[key] > b[key]) {
                return isAscending ? 1 : -1;
            } else if (a[key] < b[key]) {
                return isAscending ? -1 : 1;
            } else {
                return 0;
            }
        });

        setHistories(sortedHistories);
    };

    return (
        <>
            <Table className="long-table-data" hover>
                <thead>
                    <tr>
                        <th rowSpan={2} className="fixed-column">No</th>
                        <th colSpan={2} className="fixed-column">생산 정보</th>
                        <th colSpan={4} className="fixed-column">일일 생산 일보</th>
                        <th colSpan={5}>투입 M/R 정보</th>
                        <th colSpan={3}>계획 공수</th>
                        <th colSpan={3}>검사항목 기록</th>
                        <th colSpan={3}>품질 검사 실적</th>
                        <th colSpan={5}>ROBOT 작업 조건</th>
                        <th colSpan={2}>지그/설비 점검</th>
                        <th colSpan={2}>GAS 재고 현황</th>
                    </tr>
                    <tr>
                        <th className="fixed-column">
                            <SortableHeader label="일자" isAscending={sortStates && sortStates["workedAt"]} onSort={() => onSort("workedAt")} />
                        </th>
                        <th className="fixed-column">
                            <SortableHeader label="작업자" isAscending={sortStates && sortStates["accountName"]} onSort={() => onSort("accountName")} />
                        </th>
                        <th className="fixed-column">
                            <SortableHeader label="설비호기" isAscending={sortStates && sortStates["lineName"]} onSort={() => onSort("lineName")} />
                        </th>
                        <th className="fixed-column">
                            <SortableHeader label="품번" isAscending={sortStates && sortStates["itemCode"]} onSort={() => onSort("itemCode")} />
                        </th>
                        <th className="fixed-column">
                            <SortableHeader label="품명" isAscending={sortStates && sortStates["itemName"]} onSort={() => onSort("itemName")} />
                        </th>
                        <th className="fixed-column">
                            <SortableHeader label="실적" isAscending={sortStates && sortStates["productionQuantity"]} onSort={() => onSort("productionQuantity")} />
                        </th>
                        <th>
                            <SortableHeader label="인원" isAscending={sortStates && sortStates["workerCount"]} onSort={() => onSort("workerCount")} />
                        </th>
                        <th>
                            <SortableHeader label="총투입" isAscending={sortStates && sortStates["totalWorkTime"]} onSort={() => onSort("totalWorkTime")} />
                        </th>
                        <th>
                            <SortableHeader label="비생산" isAscending={sortStates && sortStates["nonWorkTime"]} onSort={() => onSort("nonWorkTime")} />
                        </th>
                        <th>
                            <SortableHeader label="실생산" isAscending={sortStates && sortStates["realWorkTime"]} onSort={() => onSort("realWorkTime")} />
                        </th>
                        <th>
                            <SortableHeader label="UPH" isAscending={sortStates && sortStates["uph"]} onSort={() => onSort("uph")} />
                        </th>
                        <th>
                            <SortableHeader label="예상시간" isAscending={sortStates && sortStates["plannedWorkTime"]} onSort={() => onSort("plannedWorkTime")} />
                        </th>
                        <th>
                            <SortableHeader label="손실시간" isAscending={sortStates && sortStates["lossTime"]} onSort={() => onSort("lossTime")} />
                        </th>
                        <th>
                            <SortableHeader label="UPH" isAscending={sortStates && sortStates["itemUph"]} onSort={() => onSort("itemUph")} />
                        </th>
                        <th>
                            <SortableHeader label="1항" isAscending={sortStates && sortStates["inspectionResult_1"]} onSort={() => onSort("inspectionResult_1")} />
                        </th>
                        <th>
                            <SortableHeader label="2항" isAscending={sortStates && sortStates["inspectionResult_2"]} onSort={() => onSort("inspectionResult_2")} />
                        </th>
                        <th>
                            <SortableHeader label="3항" isAscending={sortStates && sortStates["inspectionResult_3"]} onSort={() => onSort("inspectionResult_3")} />
                        </th>
                        <th>
                            <SortableHeader label="불량수량" isAscending={sortStates && sortStates["defectQuantity"]} onSort={() => onSort("defectQuantity")} />
                        </th>
                        <th>
                            <SortableHeader label="폐기수량" isAscending={sortStates && sortStates["disuseQuantity"]} onSort={() => onSort("disuseQuantity")} />
                        </th>
                        <th>
                            <SortableHeader label="수정수량" isAscending={sortStates && sortStates["modifyQuantity"]} onSort={() => onSort("modifyQuantity")} />
                        </th>
                        <th>
                            <SortableHeader label="체크유형" isAscending={sortStates && sortStates["inspectionType"]} onSort={() => onSort("inspectionType")} />
                        </th>
                        <th>
                            <SortableHeader label="전압(V)" isAscending={sortStates && sortStates["voltage"]} onSort={() => onSort("voltage")} />
                        </th>
                        <th>
                            <SortableHeader label="전류(A)" isAscending={sortStates && sortStates["current"]} onSort={() => onSort("current")} />
                        </th>
                        <th>
                            <SortableHeader label="가스유량(l/min)" isAscending={sortStates && sortStates["gasFlowRate"]} onSort={() => onSort("gasFlowRate")} />
                        </th>
                        <th>
                            <SortableHeader label="AIR압력(kgf/cm²)" isAscending={sortStates && sortStates["airPressure"]} onSort={() => onSort("airPressure")} />
                        </th>
                        <th>
                            <SortableHeader label="지그" isAscending={sortStates && sortStates["jigStatusType"]} onSort={() => onSort("jigStatusType")} />
                        </th>
                        <th>
                            <SortableHeader label="설비" isAscending={sortStates && sortStates["lineStatusType"]} onSort={() => onSort("lineStatusType")} />
                        </th>
                        <th>
                            <SortableHeader label="실병" isAscending={sortStates && sortStates["gasBottleQuantity"]} onSort={() => onSort("gasBottleQuantity")} />
                        </th>
                        <th>
                            <SortableHeader label="공병" isAscending={sortStates && sortStates["emptyBottleQuantity"]} onSort={() => onSort("emptyBottleQuantity")} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {histories.map((history, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{history.workedAt}</td>
                                <td>{history.accountName}</td>
                                <td>{history.lineName}</td>
                                <td>{history.itemCode}</td>
                                <td>{history.itemName}</td>
                                <td>{history.productionQuantity}</td>
                                <td>{history.workerCount}</td>
                                <td>{history.totalWorkTime}</td>
                                <td>{history.nonWorkTime}</td>
                                <td>{history.realWorkTime}</td>
                                <td>{history.uph}</td>
                                <td>{history.plannedWorkTime}</td>
                                <td>{history.lossTime}</td>
                                <td>{history.itemUph}</td>
                                <td>{history.inspectionResult_1 ? "OK" : "불량"}</td>
                                <td>{history.inspectionResult_2 ? "OK" : "불량"}</td>
                                <td>{history.inspectionResult_3 ? "OK" : "불량"}</td>
                                <td>{history.defectQuantity}</td>
                                <td>{history.disuseQuantity}</td>
                                <td>{history.modifyQuantity}</td>
                                <td>{history.inspectionType}</td>
                                <td>{history.voltage}</td>
                                <td>{history.current}</td>
                                <td>{history.gasFlowRate}</td>
                                <td>{history.airPressure}</td>
                                <td>{history.jigStatusType}</td>
                                <td>{history.lineStatusType}</td>
                                <td>{history.gasBottleQuantity}</td>
                                <td>{history.emptyBottleQuantity}</td>
                            </tr>
                        );
                    })}
                    {histories.length === 0 &&
                        <tr>
                            <td colSpan={30}>데이터가 없습니다.</td>
                        </tr>
                    }
                </tbody>
            </Table>
        </>
    );
}

export default TableData;