import { cloneElement, useEffect, useRef, useState } from "react";
import { SubMenuType } from "../../constants/SubMenuType";

interface Props {
    tab: SubMenuType;
    children: React.ReactElement;
    onCloseOtherTabs: () => void;
    onCloseAllTabs: () => void;
}

interface State {
    open: boolean;
    location: {
        x: number;
        y: number;
    };
}

function ContextMenu(props: Props) {
    const menuRef = useRef<HTMLDivElement>(null);
    const [state, setState] = useState<State>({
        open: false,
        location: {
            x: 0,
            y: 0,
        },
    });

    useEffect(() => {
        document.addEventListener("mousedown", onClickOff);

        return () => {
            document.removeEventListener("mousedown", onClickOff);
        };
    }, []);

    const onClickOff = (event: MouseEvent) => {
        if (
            menuRef.current &&
            event.target !== menuRef.current &&
            !menuRef.current.contains(event.target as Node)
        ) {
            setState((prev) => {
                return {
                    ...prev,
                    open: false,
                };
            });
        }
    };

    const onRightClick = (x: number, y: number) => {
        setState(() => ({
            open: true,
            location: {
                x,
                y,
            },
        }));
    };

    return (
        <div>
            {cloneElement(props.children, {
                onContextMenu: (event: MouseEvent) => {
                    event.preventDefault();
                    onRightClick(event.pageX, event.pageY);
                },
            })}
            {state.open && (
                <div
                    ref={menuRef}
                    style={{
                        position: "absolute",
                        left: state.location.x,
                        top: state.location.y,
                        margin: 0,
                        padding: 0,
                    }}
                >
                    <div
                        className="context-button"
                        onClick={() => {
                            props.onCloseOtherTabs();
                            setState((prev) => {
                                return {
                                    ...prev,
                                    open: false,
                                };
                            });
                        }}
                    >
                        다른탭 닫기
                    </div>
                    <div
                        className="context-button"
                        onClick={() => {
                            props.onCloseAllTabs();
                            setState((prev) => {
                                return {
                                    ...prev,
                                    open: false,
                                };
                            });
                        }}
                    >
                        모두 닫기
                    </div>
                </div>
            )}
        </div>
    );
}

export default ContextMenu;