import { Col, Row } from "react-bootstrap";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

export interface SortableHeaderProps {
    label: string;
    isAscending: boolean | null;
    onSort: () => void;
}

function SortableHeader(props: SortableHeaderProps) {
    return (
        <Row className="m-auto justify-content-center" style={{ width: "fit-content" }}>
            <Col className="p-0 text-nowrap">
                {props.label}
            </Col>
            <Col
                className="p-0 ms-1"
                role="button"
                onClick={() => props.onSort()}
            >
                {props.isAscending == null ? <FaSort />
                    : props.isAscending ? <FaSortUp /> : <FaSortDown />}
            </Col>
        </Row>
    );
}

export default SortableHeader;