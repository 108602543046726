import { atom } from "recoil";

export interface TokenState {
    accessToken: string | null;
}

export const keyState = atom<TokenState>({
    key: "keyState",
    default: {
        accessToken: null
    }
});