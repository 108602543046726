import ko from "date-fns/locale/ko";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangeProps } from "./DateRangeProps";
import "./style.css";

function DateRangeComponent(props: DateRangeProps) {
    const [selectionRange, setSelectionRange] = useState({
        startDate: props.startDate,
        endDate: props.endDate,
        key: "selection"
    });

    useEffect(() => {
        if (!props.isModalOpen) {
            setSelectionRange(prev => {
                return {
                    ...prev,
                    startDate: props.startDate,
                    endDate: props.endDate
                };

            });
        }
    }, [props.endDate, props.isModalOpen, props.startDate]);

    function handleChange(ranges: any) {
        setSelectionRange({
            ...selectionRange,
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate
        });
    }

    return (
        <Modal show={props.isModalOpen} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>기간 선택</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DateRange
                    className="date-wrapper"
                    locale={ko}
                    ranges={[selectionRange]}
                    dateDisplayFormat="yyyy년 MM월 dd일"
                    onChange={handleChange}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="main-button"
                    onClick={props.handleClose}
                >
                    취소
                </Button>
                <Button
                    className="main-button"
                    onClick={() => {
                        props.handleChange(selectionRange.startDate, selectionRange.endDate);
                        props.handleClose();
                    }}
                >
                    선택
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DateRangeComponent;