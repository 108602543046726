import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { PieChartData } from "./PieChartData";

export interface PieChartProps {
    title: string;
    tableHeader: string;
    years: number[];
    url: (year: number) => string;
}

function PieChart(props: PieChartProps) {
    const tableParentRef = useRef<HTMLDivElement>(null);
    const [tableMaxHeight, setTableMaxHeight] = useState<number>(0);

    const [selectedYear, setSelectedYear] = useState<number>(0);
    const [chartData, setChartData] = useState<PieChartData[]>([]);
    const [series, setSeries] = useState<number[]>([]);
    const [labels, setLabels] = useState<string[]>([]);

    useEffect(() => {
        if (tableParentRef.current) {
            setTableMaxHeight(tableParentRef.current.clientHeight);
        }
    }, []);

    useEffect(() => {
        setSelectedYear(props.years[props.years.length - 1]);
    }, [props.years]);

    const getPieChartData = useCallback(() => {
        axios.get<PieChartData[]>(props.url(selectedYear)).then((response) => {
            setChartData(response.data);
        }).catch(() => { });
    }, [selectedYear]);

    useEffect(() => {
        if (0 < selectedYear) {
            getPieChartData();
        }
    }, [selectedYear]);

    useEffect(() => {
        const top5 = chartData.slice(0, 5);
        const etc = chartData.slice(5);
        const etcValue = etc.reduce((acc, cur) => acc + cur.value, 0);

        const series = top5.map((data) => data.value);
        const labels = top5.map((data) => data.name);

        if (0 < etc.length) {
            series.push(etcValue);
            labels.push("기타");
        }

        setSeries(series);
        setLabels(labels);
    }, [chartData]);

    return (
        <Container className="wrapper pt-2">
            <Row className="mt-2 w-50 align-items-center">
                <Col>
                    <span className="bold-text fs-6">
                        {props.title}
                    </span>
                </Col>
                <Col>
                    <Form.Select
                        value={selectedYear}
                        onChange={(e) => {
                            const year = Number(e.target.value);
                            setSelectedYear(year);
                        }}
                    >
                        {props.years.length === 0 &&
                            <option value={0}>None</option>
                        }
                        {0 < props.years.length && props.years.map((year, index) => (
                            <option key={index} value={year}>{year}</option>
                        ))}
                    </Form.Select>
                </Col>
            </Row>
            <Row className="h-100 m-0 mt-4">
                <Col className="p-0">
                    <ReactApexChart
                        type="pie"
                        width={350}
                        series={series}
                        options={{
                            labels: labels,
                            legend: {
                                position: 'bottom'
                            }
                        }}
                    />
                </Col>
                <Col ref={tableParentRef} className="p-0">
                    <div
                        className="w-100 mt-3 ps-2 pe-2"
                        style={{
                            height: `${tableMaxHeight}px`,
                            maxHeight: `${tableMaxHeight}px`
                        }}
                    >
                        <div className="table-wrapper h-100 w-100">
                            <Table className="table-data w-100" hover>
                                <thead>
                                    <tr>
                                        <th>{props.tableHeader}</th>
                                        <th>불량률(%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {chartData.map((data: PieChartData, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>{data.name}</td>
                                                <td>{data.value}</td>
                                            </tr>
                                        );
                                    })}
                                    {chartData.length === 0 &&
                                        <tr>
                                            <td colSpan={2}>데이터가 없습니다.</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default PieChart;