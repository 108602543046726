import { Col, Row } from "react-bootstrap";
import { MenuType, getMenuName } from "../../constants/MenuType";

export interface MenuItemProps {
    menuType: MenuType;
}

function MenuItem(props: MenuItemProps) {
    return (
        <Row className="align-items-center pt-2 pb-2 ps-2">
            <Col className="small-text">
                {getMenuName(props.menuType)}
            </Col>
        </Row>
    );
}

export default MenuItem;