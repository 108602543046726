import axios from "axios";
import saveAs from "file-saver";
import { useEffect, useRef, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { useSetRecoilState } from "recoil";
import Delimiter from "../../../components/delimiter/Delimiter";
import ConfirmDialog from "../../../components/dialog/ConfirmDialog";
import { ApiUrl } from "../../../constants/ApiUrl";
import { toastState } from "../../../store/toastStore";
import Create from "./components/Create";
import Manage from "./components/Manage";
import Search from "./components/Search";
import TableData from "./components/TableData";
import { AccountDto } from "./dto/AccountDto";
import { AccountSearchDto } from "./dto/AccountSearchDto";

function AccountInfo() {
    const setToasts = useSetRecoilState(toastState);
    const tableRef = useRef<HTMLDivElement>(null);
    const [tableHeight, setTableHeight] = useState<number>(0);

    const [accounts, setAccounts] = useState<AccountDto[]>([]);
    const [searchDto, setSearchDto] = useState<AccountSearchDto | null>(null);
    const [checkedSeqs, setCheckedSeqs] = useState<number[]>([]);

    const [isCreateVisible, setIsCreateVisible] = useState<boolean>(false);
    const [isDeleteVisible, setIsDeleteVisible] = useState<boolean>(false);
    const [isChangeUseTabletVisible, setIsChangeUseTabletVisible] = useState<boolean>(false);
    const [targetAccount, setTargetAccount] = useState<AccountDto | null>(null);

    useEffect(() => {
        setTimeout(() => {
            if (tableRef.current) {
                setTableHeight(window.innerHeight - tableRef.current.getBoundingClientRect().top);
            }
        }, 100);
    }, []);

    const search = (dto: AccountSearchDto) => {
        setSearchDto(dto);

        axios.get<AccountDto[]>(ApiUrl.SEARCH_ACCOUNT(
            dto.departmentName,
            dto.positionName,
            dto.name,
            dto.countryName
        )).then((response) => {
            setAccounts(response.data);
        }).catch(() => { });
    }

    const handleUpdate = () => {
        setIsChangeUseTabletVisible(false);

        if (!targetAccount) {
            return;
        }

        axios.put(ApiUrl.ACCOUNT, targetAccount).then(() => {
            setToasts((oldToasts) => [...oldToasts, {
                id: Date.now(),
                title: "현장실적 연계 여부 수정",
                message: "수정되었습니다."
            }]);

            setTargetAccount(null);
            search(searchDto!);
        }).catch(() => { });
    }

    const handleDelete = () => {
        setIsDeleteVisible(false);

        axios.post(ApiUrl.DELETE_ACCOUNT, { seqs: checkedSeqs }).then(() => {
            setToasts((oldToasts) => [...oldToasts, {
                id: Date.now(),
                title: "직원 정보 삭제",
                message: "삭제되었습니다."
            }]);

            search(searchDto!);
        }).catch(() => { });
    }

    const download = () => {
        if (searchDto === null) {
            return;
        }

        axios.get(ApiUrl.DOWNLOAD_ACCOUNT(
            searchDto.departmentName,
            searchDto.positionName,
            searchDto.name,
            searchDto.countryName
        ), { responseType: "arraybuffer" }).then((response) => {
            const blob = new Blob([response.data], { type: response.headers["content-type"] });

            saveAs(blob, `직원정보_${new Date().toISOString().slice(0, 10).replace(/-/g, "")}.xlsx`);
        }).catch(() => { });
    }

    return (
        <>
            <Container className="content-wrapper">
                <Search onSearch={search} />
                <Delimiter />
                <Manage
                    totalCount={accounts.length}
                    onCreateClick={() => setIsCreateVisible(true)}
                    onDeleteClick={() => {
                        if (checkedSeqs.length === 0) {
                            setToasts((oldToasts) => [...oldToasts, {
                                id: Date.now(),
                                title: "직원 정보 삭제",
                                message: "선택된 항목이 없습니다."
                            }]);
                            return;
                        }

                        setIsDeleteVisible(true);
                    }}
                    onDownloadClick={download}
                />
                <div
                    ref={tableRef}
                    className="w-100 mt-3 ps-2 pe-2"
                    style={{
                        height: `${tableHeight}px`,
                        maxHeight: `${tableHeight}px`
                    }}
                >
                    <div className="table-wrapper h-100 w-100">
                        <TableData
                            data={accounts}
                            onClick={(accountDto) => {
                                setTargetAccount(accountDto);
                                setIsCreateVisible(true);
                            }}
                            onCheckedChange={(seqs) => setCheckedSeqs(seqs)}
                            onUseTabletChange={(accountDto) => {
                                setTargetAccount({
                                    ...accountDto,
                                    useTablet: !accountDto.useTablet
                                });
                                setIsChangeUseTabletVisible(true);
                            }}
                        />
                    </div>
                </div>
            </Container>
            {isChangeUseTabletVisible &&
                <Modal show={true} onHide={() => setIsChangeUseTabletVisible(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            현장실적 연계 여부 수정
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <span className="fs-6">
                                {targetAccount?.name}의 현장실적 연계 여부를 {targetAccount?.useTablet ? "활성화" : "해제"}하시겠습니까?
                            </span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary" onClick={() => setIsChangeUseTabletVisible(false)}>
                            취소
                        </button>
                        <button className="btn btn-danger" onClick={handleUpdate}>
                            수정
                        </button>
                    </Modal.Footer>
                </Modal>
            }
            {isCreateVisible &&
                <Create
                    accountDto={targetAccount}
                    onCreated={() => {
                        search(searchDto!);
                        setTargetAccount(null);
                        setIsCreateVisible(false);
                    }}
                    onClose={() =>  {
                        setTargetAccount(null);
                        setIsCreateVisible(false);
                    }}
                />
            }
            {isDeleteVisible &&
                <ConfirmDialog
                    title="직원 정보 삭제"
                    message={`선택된 ${checkedSeqs.length}개의 직원 정보를 삭제하시겠습니까?`}
                    onConfirm={() => handleDelete()}
                    onClose={() => setIsDeleteVisible(false)}
                />
            }
        </>
    );
}

export default AccountInfo;