import { useEffect, useState } from "react";
import { FormCheck, Table } from "react-bootstrap";
import SortableHeader from "../../../../components/table/SortableHeader";
import { AccountType, getAccountTypeName } from "../../../../constants/AccountType";
import { AccountDto } from "../dto/AccountDto";

export interface TableDataProps {
    data: AccountDto[];
    onClick: (account: AccountDto) => void;
    onCheckedChange: (seqs: number[]) => void;
    onUseTabletChange: (accountDto: AccountDto) => void;
}

type SortState = Record<keyof AccountDto, boolean | null>;

function TableData(props: TableDataProps) {
    const [accounts, setAccounts] = useState<AccountDto[]>([]);
    const [sortStates, setSortStates] = useState<SortState | null>(null);

    const [isAllChecked, setIsAllChecked] = useState(false);
    const [checkedSeqs, setCheckedSeqs] = useState<number[]>([]);

    useEffect(() => {
        setAccounts(props.data);

        if (0 < props.data.length) {
            initSortStates();
        }
    }, [props.data]);

    const initSortStates = () => {
        const initialStateKeys = Object.keys(props.data[0]) as (keyof AccountDto)[];

        setSortStates(initialStateKeys.reduce((acc, key) => {
            acc[key] = null;
            return acc;
        }, {} as SortState));
    }

    const onSort = (key: keyof AccountDto) => {
        const isAscending = sortStates![key] === null ? true : !sortStates![key];

        setSortStates((prev) => ({
            ...prev!,
            [key!]: isAscending
        }));

        for (const stateKey in sortStates) {
            if (stateKey !== key) {
                setSortStates((prev) => ({
                    ...prev!,
                    [stateKey]: null
                }));
            }
        }

        const sortedAccounts = [...accounts].sort((a, b) => {
            if (a[key] > b[key]) {
                return isAscending ? 1 : -1;
            } else if (a[key] < b[key]) {
                return isAscending ? -1 : 1;
            } else {
                return 0;
            }
        });

        setAccounts(sortedAccounts);
    }

    useEffect(() => {
        props.onCheckedChange(checkedSeqs);
    }, [checkedSeqs]);

    const toggleAllChecked = (checked: boolean) => {
        setIsAllChecked(checked);
        setCheckedSeqs(checked ? props.data.map((item) => item.seq) : []);
    }

    return (
        <Table className="table-data w-100" hover>
            <thead>
                <tr>
                    <th>
                        <FormCheck.Input
                            type="checkbox"
                            className="table-checkbox all-check"
                            checked={isAllChecked}
                            onChange={(e) => toggleAllChecked(e.target.checked)}
                        />
                    </th>
                    <th>
                        <SortableHeader label="구분" isAscending={sortStates && sortStates["type"]} onSort={() => onSort("type")} />
                    </th>
                    <th>
                        <SortableHeader label="이름" isAscending={sortStates && sortStates["name"]} onSort={() => onSort("name")} />
                    </th>
                    <th>
                        <SortableHeader label="부서" isAscending={sortStates && sortStates["departmentName"]} onSort={() => onSort("departmentName")} />
                    </th>
                    <th>
                        <SortableHeader label="직급" isAscending={sortStates && sortStates["positionName"]} onSort={() => onSort("positionName")} />
                    </th>
                    <th>
                        <SortableHeader label="국적" isAscending={sortStates && sortStates["country"]} onSort={() => onSort("country")} />
                    </th>
                    <th>
                        <SortableHeader label="휴대전화" isAscending={sortStates && sortStates["phone"]} onSort={() => onSort("phone")} />
                    </th>
                    <th>
                        <SortableHeader label="이메일" isAscending={sortStates && sortStates["email"]} onSort={() => onSort("email")} />
                    </th>
                    <th>
                        <SortableHeader label="입사일" isAscending={sortStates && sortStates["joinedAt"]} onSort={() => onSort("joinedAt")} />
                    </th>
                    <th>
                        <SortableHeader label="근속월수" isAscending={sortStates && sortStates["monthOfService"]} onSort={() => onSort("monthOfService")} />
                    </th>
                    <th>
                        <SortableHeader label="주소" isAscending={sortStates && sortStates["address"]} onSort={() => onSort("address")} />
                    </th>
                    <th>
                        <SortableHeader label="현장실적 연계" isAscending={sortStates && sortStates["useTablet"]} onSort={() => onSort("useTablet")} />
                    </th>
                </tr>
            </thead>
            <tbody>
                {accounts.map(account => {
                    return (
                        <tr
                            key={account.seq}
                            role="button"
                            onClick={(e) => {
                                if ((e.target as HTMLInputElement).type === "checkbox") {
                                    return;
                                }

                                props.onClick(account);
                            }}
                        >
                            <td>
                                <FormCheck.Input
                                    type="checkbox"
                                    className="table-checkbox"
                                    checked={checkedSeqs.includes(account.seq)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setCheckedSeqs([...checkedSeqs, account.seq]);
                                        } else {
                                            setCheckedSeqs(checkedSeqs.filter((seq) => seq !== account.seq));
                                        }
                                    }}
                                />
                            </td>
                            <td>{getAccountTypeName(account.type)}</td>
                            <td>{account.name}</td>
                            <td>{account.departmentName === "" ? "-" : account.departmentName}</td>
                            <td>{account.positionName === "" ? "-" : account.positionName}</td>
                            <td>{account.country}</td>
                            <td>{account.phone}</td>
                            <td>{account.email === "" ? "-" : account.email}</td>
                            <td>{account.joinedAt}</td>
                            <td>{account.monthOfService}개월</td>
                            <td>{`${account.address} ${account.addressRemain}`}</td>
                            <td>
                                {account.type === AccountType.FIELD_WORKER &&
                                    <FormCheck.Input
                                        type="checkbox"
                                        className="table-checkbox"
                                        checked={account.useTablet}
                                        onChange={() => props.onUseTabletChange(account)}
                                    />
                                }
                            </td>
                        </tr>
                    );
                })}
                {accounts.length === 0 &&
                    <tr>
                        <td colSpan={12} className="text-center">데이터가 없습니다.</td>
                    </tr>
                }
            </tbody>
        </Table>
    );
}

export default TableData;