import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DateRangeButton from "../../../../components/button/DateRangeButton";
import DebouncedInputGroup from "../../../../components/input/DebouncedInputGroup";
import { WeldingHistorySearchDto, emptyWeldingHistorySearchDto } from "../dto/WeldingHistorySearchDto";

export interface SearchProps {
    onSearch: (searchDto: WeldingHistorySearchDto) => void;
}

function Search(props: SearchProps) {
    const [startDate, setStartDate] = useState<Date>(new Date("2024-06-01"));
    const [endDate, setEndDate] = useState<Date>(new Date());
    // const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    // const [endDate, setEndDate] = useState<Date>(new Date());
    const [searchDto, setSearchDto] = useState<WeldingHistorySearchDto>(emptyWeldingHistorySearchDto);

    useEffect(() => {
        setSearchDto({
            ...searchDto,
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(endDate, "yyyy-MM-dd")
        });
    }, []);

    useEffect(() => {
        props.onSearch(searchDto);
    }, [searchDto]);

    const setDate = (startDate: Date, endDate: Date) => {
        setStartDate(startDate);
        setEndDate(endDate);

        setSearchDto({
            ...searchDto,
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(endDate, "yyyy-MM-dd")
        });
    }

    return (
        <Row className="m-0 pt-2 h-100 align-items-center">
            <Col xs={2}>
                <DateRangeButton
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(startDate, endDate) => setDate(startDate, endDate)}
                />
            </Col>
            <Col xs={2}>
                <DebouncedInputGroup
                    label="LINE"
                    onChange={(value) => setSearchDto((prev) => {
                        return {
                            ...prev,
                            lineName: value
                        }
                    })}
                />
            </Col>
            <Col xs={3}>
                <DebouncedInputGroup
                    label="품번"
                    onChange={(value) => setSearchDto((prev) => {
                        return {
                            ...prev,
                            itemCode: value
                        }
                    })}
                />
            </Col>
            <Col xs={3}>
                <DebouncedInputGroup
                    label="품명"
                    onChange={(value) => setSearchDto((prev) => {
                        return {
                            ...prev,
                            itemName: value
                        }
                    })}
                />
            </Col>
            <Col xs={2}>
                <DebouncedInputGroup
                    label="작업자"
                    onChange={(value) => setSearchDto((prev) => {
                        return {
                            ...prev,
                            accountName: value
                        }
                    })}
                />
            </Col>
        </Row>
    );
}

export default Search;