import { useEffect, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";

export interface DebouncedInputGroupProps {
    label: string;
    onChange: (value: string) => void;
}

const DebouncedInputGroup = (props: DebouncedInputGroupProps) => {
    const [value, setValue] = useState<string | null>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (value === null) {
            return;
        }

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            props.onChange(value);
        }, 500);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [value]);

    return (
        <InputGroup>
            <InputGroup.Text>
                {props.label}
            </InputGroup.Text>
            <Form.Control
                type="text"
                value={value || ""}
                onChange={(e) => setValue(e.target.value)}
            />
        </InputGroup>
    );
}

export default DebouncedInputGroup;