import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DebouncedInputGroup from "../../../../components/input/DebouncedInputGroup";
import SearchInputGroup from "../../../../components/input/SearchInputGroup";
import { ApiUrl } from "../../../../constants/ApiUrl";
import { SubInfoType } from "../../../../constants/SubInfoType";
import SubInfo from "../../subInfo/SubInfo";
import { ItemSearchDto, emptyItemSearchDto } from "../dto/ItemSearchDto";

export interface SearchProps {
    onSearch: (searchDto: ItemSearchDto) => void;
}

function Search(props: SearchProps) {
    const [isSubInfoVisible, setIsSubInfoVisible] = useState<boolean>(false);
    const [subInfoType, setSubInfoType] = useState<SubInfoType>(SubInfoType.LINE);
    const [searchDto, setSearchDto] = useState<ItemSearchDto>(emptyItemSearchDto);

    useEffect(() => {
        props.onSearch(searchDto);
    }, [searchDto]);

    return (
        <>
            <Row className="m-0 pt-2 h-100 align-items-center">
                <Col>
                    <DebouncedInputGroup
                        label="분류"
                        onChange={(value) => setSearchDto((prev) => {
                            return {
                                ...prev,
                                type: value
                            }
                        })}
                    />
                </Col>
                <Col>
                    <DebouncedInputGroup
                        label="품번"
                        onChange={(value) => setSearchDto((prev) => {
                            return {
                                ...prev,
                                code: value
                            }
                        })}
                    />
                </Col>
                <Col>
                    <DebouncedInputGroup
                        label="품명"
                        onChange={(value) => setSearchDto((prev) => {
                            return {
                                ...prev,
                                name: value
                            }
                        })}
                    />
                </Col>
                <Col>
                    <SearchInputGroup
                        label="LINE"
                        value={searchDto.lineName}
                        searchUrl={ApiUrl.SEARCH_LINE}
                        onClick={() => {
                            setSubInfoType(SubInfoType.LINE);
                            setIsSubInfoVisible(true);
                        }}
                        onChange={(result) => {
                            setSearchDto((prev) => {
                                return {
                                    ...prev,
                                    lineName: result.name
                                }
                            })
                        }}
                    />
                </Col>
            </Row>
            <SubInfo
                isVisible={isSubInfoVisible}
                subInfoType={subInfoType}
                onSelect={(dto) => {
                    setSearchDto((prev) => {
                        return {
                            ...prev,
                            lineName: dto.name
                        }
                    });

                    setIsSubInfoVisible(false);
                }}
                onClose={() => setIsSubInfoVisible(false)}
            />
        </>
    );
}

export default Search;