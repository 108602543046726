export const unixTimeToDate = (unixTime: number): Date => {
    return new Date(unixTime);
}

export const formatPhoneNumber = (phoneNumber: string): string => {
    phoneNumber = phoneNumber.replace(/[^0-9]/g, "");

    if (11 < phoneNumber.length) {
        phoneNumber = phoneNumber.substring(0, 11);
    }

    if (3 < phoneNumber.length) {
        if (6 < phoneNumber.length && phoneNumber.length <= 10) {
            phoneNumber = phoneNumber.substring(0, 3) + "-" + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6);
        } else if (6 < phoneNumber.length && phoneNumber.length <= 11) {
            phoneNumber = phoneNumber.substring(0, 3) + "-" + phoneNumber.substring(3, 7) + "-" + phoneNumber.substring(7);
        } else {
            phoneNumber = phoneNumber.substring(0, 3) + "-" + phoneNumber.substring(3);
        }
    }

    return phoneNumber;
}