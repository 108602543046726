import { Col, Container, Row } from "react-bootstrap";
import Content from "./content/Content";
import Menu from "./menu/Menu";
import "./style.css";

function Main() {
    return (
        <Container className="main-wrapper">
            <Row>
                <Col xs={1} className="fill-background">
                    <Menu />
                </Col>
                <Col xs={11}>
                    <Content />
                </Col>
            </Row>
        </Container>
    );
}

export default Main;