import { MenuType } from "./MenuType";

export enum SubMenuType {
    DASHBOARD, WELDING_HISTORY, PERFORMANCE_BY_LINES, ANALYSIS, ACCOUNT_INFO, ITEM_INFO
}

export const getSubMenuTypes = (menuType: MenuType) => {
    switch (menuType) {
        case MenuType.WELDING:
            return [SubMenuType.WELDING_HISTORY, SubMenuType.PERFORMANCE_BY_LINES];
        case MenuType.DASHBOARD:
            return [SubMenuType.ANALYSIS];
        case MenuType.INFO:
            return [SubMenuType.ACCOUNT_INFO, SubMenuType.ITEM_INFO];
    }
}

export const getSubMenuName = (subMenuType: SubMenuType) => {
    switch (subMenuType) {
        case SubMenuType.DASHBOARD:
            return "홈";
        case SubMenuType.WELDING_HISTORY:
            return "용접 일보";
        case SubMenuType.PERFORMANCE_BY_LINES:
            return "실적 집계";
        case SubMenuType.ANALYSIS:
            return "실적 분석";
        case SubMenuType.ACCOUNT_INFO:
            return "직원 정보";
        case SubMenuType.ITEM_INFO:
            return "품목 정보";
    }
}