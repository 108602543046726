import axios from "axios";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DateRangeButton from "../../../../components/button/DateRangeButton";
import { ApiUrl } from "../../../../constants/ApiUrl";
import { WeldingPerformanceSearchDto, emptyWeldingPerformanceSearchDto } from "../dto/WeldingPerformanceSearchDto";

export interface SearchProps {
    onSearch: (searchDto: WeldingPerformanceSearchDto) => void;
}

function Search(props: SearchProps) {
    const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [lineNames, setLineNames] = useState<string[]>([]);

    const [searchDto, setSearchDto] = useState<WeldingPerformanceSearchDto>(emptyWeldingPerformanceSearchDto);

    useEffect(() => {
        getLineNames();

        setSearchDto({
            ...searchDto,
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(endDate, "yyyy-MM-dd")
        });
    }, []);

    const getLineNames = () => {
        axios.get<string[]>(ApiUrl.WELDING_PERFORMANCE_LINE).then((response) => {
            setLineNames(response.data);
        }).catch(() => { });
    }

    useEffect(() => {
        props.onSearch(searchDto);
    }, [searchDto]);

    const setDate = (startDate: Date, endDate: Date) => {
        setStartDate(startDate);
        setEndDate(endDate);

        setSearchDto({
            ...searchDto,
            startDate: format(startDate, "yyyy-MM-dd"),
            endDate: format(endDate, "yyyy-MM-dd")
        });
    }

    return (
        <Row className="m-0 pt-2 h-100 align-items-center">
            <Col xs={2}>
                <Form.Select
                    defaultValue={""}
                    onChange={(e) => setSearchDto((prev) => {
                        return {
                            ...prev,
                            lineName: e.target.value
                        }
                    })}
                >
                    <option value={""}>설비 이름</option>
                    {lineNames.map((name, index) => (
                        <option key={index} value={name}>{name}</option>
                    ))}
                </Form.Select>
            </Col>
            <Col xs={2}>
                <DateRangeButton
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(startDate, endDate) => setDate(startDate, endDate)}
                />
            </Col>
        </Row>
    );
}

export default Search;