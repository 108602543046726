export interface ItemSearchDto {
    type: string,
    code: string,
    name: string,
    lineName: string
}

export const emptyItemSearchDto: ItemSearchDto = {
    type: "",
    code: "",
    name: "",
    lineName: ""
}