import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { SubInfoType, getSubInfoName, getSubInfoUrl } from "../../../../constants/SubInfoType";
import { SubInfoDto } from "../dto/SubInfoDto";

export interface DirectCreateSubInfoProps {
    subInfoType: SubInfoType;
    value: string;
    onCreate: (dto: SubInfoDto) => void;
    onClose: () => void;
}

function DirectCreateSubInfo(props: DirectCreateSubInfoProps) {
    const handleCreate = () => {
        axios.post<number>(getSubInfoUrl(props.subInfoType), { name: props.value }).then((response) => {
            props.onCreate({ seq: response.data, name: props.value });
        }).catch(() => { });
    }

    return (
        <Modal show={true} onHide={() => props.onClose()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {getSubInfoName(props.subInfoType)} 추가
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                등록된 {getSubInfoName(props.subInfoType)}이(가) 아닙니다.
                <br />
                <strong>[{props.value}]</strong>을(를) 추가하시겠습니까?
            </Modal.Body>
            <Modal.Footer>
                <Button className="main-button" onClick={() => handleCreate()}>
                    추가하기
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DirectCreateSubInfo;