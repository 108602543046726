import { Modal } from "react-bootstrap";

export interface ConfirmDialogProps {
    title: string;
    message: string;
    buttonName?: string;
    onConfirm: () => void;
    onClose: () => void;
}

function ConfirmDialog(props: ConfirmDialogProps) {
    return (
        <Modal show={true} onHide={() => props.onClose()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <span className="fs-6">
                        {props.message}
                    </span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => props.onClose()}>
                    취소
                </button>
                <button className="btn btn-danger" onClick={() => props.onConfirm()}>
                    {props.buttonName || "확인"}
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmDialog;